import apiClient from "../http-common";
import { useQuery } from "@tanstack/react-query";

export const useInformationFiles = (category, page = 0) => {
    return useQuery(
        {
            queryKey: ['information-files/index', { category, page }],
            queryFn: async () => {
                return await apiClient.get(`information-files/index`, {
                    params: {
                        page,
                        category
                        // page: query.get("page")
                    }
                });

            },
            staleTime: 3000000
        }
    );
}
import React, { useEffect, useState } from "react";
import apiClient from "../../api/http-common";
// import { useQuery, useMutation } from "react-query";
import { useMutation } from "@tanstack/react-query";

// import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LoadingButton from '@mui/lab/LoadingButton';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const ImageUploader = ({
    src,
    alt,
    variant = 'circular',
    imgSx,
    onUpload = (data) => { }
}) => {

    const { mutate: upload, isPending } = useMutation({
        mutationKey: 'upload/upload',
        mutationFn: async (file) => {
            const formData = new FormData();
            formData.append("imageFile", file);
            return await apiClient.post("upload/upload", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        onSuccess: (res) => {
            const result = {
                status: res.status + "-" + res.statusText,
                headers: res.headers,
                data: res.data,
            };
            // console.log('result', result)
            // setFileResult(result.data)
            onUpload(result.data)
            // setValue({ ...value, avatar: result.data.file_id })
            // navigate(`/restaurants/view/${id}`);
            // setGetResult(fortmatResponse(result));
        },
        onError: (err) => {
            console.error('err', err)
            // setGetResult(fortmatResponse(err.response?.data || err));
        }
    })
    return <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
        <Avatar
            alt={alt}
            sx={imgSx}
            variant={variant}
            src={src} />
        <LoadingButton loading={isPending} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            Upload file
            <VisuallyHiddenInput type="file" onChange={(e) => upload(e.target.files[0])} />
        </LoadingButton>
    </Box>
}
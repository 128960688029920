import apiClient from "../http-common";
import { useMutation } from "@tanstack/react-query";


export const useWorkshiftsCreate = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: ["workshift/create"],
        mutationFn: async (value) => {
            return await apiClient.post("workshift/create", value);
        },
        onSuccess: (res) => {
            onSuccess(res)
        },
        onError
    })
}
import { useMutation } from "@tanstack/react-query";
import apiClient, { queryClient } from "../http-common";
import React, { useState } from 'react';

export const useAddRestFile = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: "rest-files",
        mutationFn: async ({
            rest_id,
            file_id,
            category
        }) => {
            return await apiClient.post("rest-files/create", {
                rest_id,
                file_id,
                category
            });
        },
        onSuccess: (res) => {
            queryClient.invalidateQueries({ queryKey: ['rest-files/index'] })
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
            console.log('rest-comments/create err', err)
        },
    })
}
import apiClient from "../http-common";
import { useQuery } from "@tanstack/react-query";

export const useFilesByUser = (user_id, category, page = 0) => {
    console.log('user_id', user_id)
    return useQuery(
        {
            queryKey: ['users-personal-files/index', { user_id, category, page }],
            queryFn: async () => {
                return await apiClient.get(`users-personal-files/index`, {
                    params: {
                        user_id,
                        page,
                        category
                        // page: query.get("page")
                    }
                });

            },
            staleTime: 3000000
        }
    );
}
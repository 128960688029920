import { createContext, useContext, useState, useMemo, useEffect } from 'react';


const defaultContext = {
    setTitle: (title) => { },
    title: ''
}

const TitleContext = createContext(defaultContext);

export const TitleProvider = (props) => {
    const [title, setTitle] = useState('')
    const context = useMemo(() => ({
        title,
        setTitle: (str) => {
            document.title = str + ' - Tequila Team Russia - Manage'
            setTitle(str)
        }
    }), [title, setTitle])
    return <TitleContext.Provider {...props} value={context} />
}

export const useSetTitle = (title = '') => {
    const { setTitle } = useContext(TitleContext);
    useEffect(() => {
        setTitle(title)
        return () => setTitle('')
    }, [title])
}
export const useGetTitle = () => {
    const { title } = useContext(TitleContext);
    return title
}
import React, { } from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { VARIABLES } from '../../../utils/variables';
import { formateDate } from "../../../utils/utils";
import Box from '@mui/material/Box';


const Image = styled('img')(
    ({ theme }) => `
    width: 100%;
    height: auto;
    display: block;
  `,
);


export const Profile = ({
    value
}) => {
    return <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
            <Image
                alt={value?.displayName}
                src={VARIABLES.FILES_URL + `${value?.avatar?.file}`}
            />
        </Grid>

        <Grid item xs={12} sm={10}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        {value.displayName}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Typography component="span" variant="body1" color="text.secondary">
                            День рождения:&nbsp;
                        </Typography>
                        <Typography component="span" variant="body1" color="text.primary">
                            {value.birth_date ? formateDate(value.birth_date) : 'Н/Д'}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography component="span" variant="body1" color="text.secondary">
                            Рост:&nbsp;
                        </Typography>
                        <Typography component="span" variant="body1" color="text.primary">
                            {value.height || 'Н/Д'}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography component="span" variant="body1" color="text.secondary">
                            Почта:&nbsp;
                        </Typography>
                        <Typography component="span" variant="body1" color="text.primary">
                            {value.email}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography component="span" variant="body1" color="text.secondary">
                            Логин:&nbsp;
                        </Typography>
                        <Typography component="span" variant="body1" color="text.primary">
                            {value.username}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography component="span" variant="body1" color="text.secondary">
                            Телефон:&nbsp;
                        </Typography>
                        <Typography component="span" variant="body1" color="text.primary">
                            {value.phone || 'Н/Д'}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography component="span" variant="body1" color="text.secondary">
                            Город:&nbsp;
                        </Typography>
                        <Typography component="span" variant="body1" color="text.primary">
                            {value.city?.name || 'Н/Д'}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}
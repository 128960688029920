import apiClient, { queryClient } from "../http-common";
import { useMutation } from "@tanstack/react-query";

export const useChangePassword = (id) => {
    return useMutation({
        mutationKey: [`user/change-password`, { id }],
        mutationFn: async (data) => {
            return await apiClient.put("user/change-password", data, { params: { id } });
        },
        onSuccess: (res) => {
            if (res.data.error) {
                console.log(res.data)
                return;
            }
        },
        onError: (err) => {
            console.log('user/update', err)
        },
    })
}
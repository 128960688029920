import React, { useEffect, useRef, useState } from "react";
import apiClient from "../../api/http-common";
// import { useQuery, useMutation } from "react-query";
import { useMutation } from "@tanstack/react-query";
import { ContentLayout } from '../../Components/ContentLayout/ContentLayout'
import {
    useNavigate,
    useParams
} from "react-router-dom";
// import { useRestaurantView } from '../RestaurantsView/RestaurantsView'
import { useRestaurantView } from '../../api/restaurants/useRestaurantView'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import { ImageUploader } from '../../Components/ImageUploader/ImageUploader'
import LoadingButton from '@mui/lab/LoadingButton';
import { useRestaurantsUpdate } from '../../api/restaurants/useRestaurantsUpdate'
import { CitySelect } from "../../Components/CitySelect/CitySelect";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { YandexMap } from "../../Components/YandexMap/YandexMap";
import { RequestErrorMessage } from "../../ui/RequestErrorMessage/RequestErrorMessage";
import { Preloader } from "../../ui/Preloader/Preloader";
import { VARIABLES } from '../../utils/variables';

export const RestaurantsEditPage = ({ className, ...props }) => {
    const [fileResult, setFileResult] = useState(null)

    const { id } = useParams()

    const [place, setPlace] = useState([-16.460174, -133.395840])
    const ymap = useRef(null)

    const { value, isLoading } = useRestaurantView(id)

    useEffect(() => {
        setPlace([value?.longitude, value?.latitude])
    }, [value?.longitude, value?.latitude])


    const navigate = useNavigate();
    const { mutate, isPending, error, reset } = useRestaurantsUpdate(id, {
        onSuccess: (res) => {
            navigate(`/restaurants/view/${id}`);
        }
    })

    if (isLoading) return <ContentLayout
        title={<Preloader />}
    />
    if (!value) return <ContentLayout
        title={`404`}
    />

    return <ContentLayout
        title={`Редактировние "${value?.name}"`}
        action={{
            to: `/restaurants/view/${value?.id}`,
            title: 'Просмотр'
        }}
    >
        <Box component={'form'} noValidate onSubmit={(e) => {
            e.preventDefault()
            const data = new FormData(e.currentTarget);
            const v = [...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {
                avatar: fileResult?.file_id ? fileResult?.file_id : value.avatar?.id
            })
            console.log('data', [...data.entries()], v)
            mutate(v)
        }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ImageUploader
                        alt={value?.name}
                        src={VARIABLES.FILES_URL + `${fileResult?.url || value?.avatar?.file}`}
                        onUpload={(data) => {
                            setFileResult(data)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Название"
                        name="name"
                        id="name"
                        fullWidth
                        defaultValue={value?.name}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Комиссия"
                        name="commission"
                        id="commission"
                        fullWidth
                        defaultValue={value?.commission}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Базовая комиссия"
                        name="base_commission"
                        id="base_commission"
                        fullWidth
                        defaultValue={value?.base_commission}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Описание"
                        name="description"
                        id="description"
                        multiline
                        fullWidth
                        rows={4}
                        defaultValue={value?.description}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Комметарий"
                        name="comment"
                        id="comment"
                        multiline
                        fullWidth
                        rows={4}
                        defaultValue={value?.comment}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Адрес"
                        name="address"
                        id="address"
                        multiline
                        fullWidth
                        rows={4}
                        defaultValue={value?.address}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type='number'
                        label="ИД организации в Яндекс картах"
                        name="yandex_oid"
                        id="yandex_oid"
                        fullWidth
                        defaultValue={value?.yandex_oid}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="restSelectCity-select-small-label">Город</InputLabel>
                        <CitySelect
                            // anyVariant
                            label="Город"
                            name="city_id"
                            labelId="restSelectCity-select-small-label"
                            id="restSelectCity-select-small"
                            onChange={(v) => {
                                const pos = [v?.longitude, v?.latitude]
                                ymap.current?.setCenter(pos)
                                setPlace(pos)
                            }}
                            value={value?.city?.id}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <YandexMap
                        height={'40vh'}
                        latitudeName={'latitude'}
                        longitudeName={'longitude'}
                        key={`edit_r_${value?.id}`}
                        onChange={setPlace}
                        onLoad={(m) => ymap.current = m}
                        place={place}
                    />
                </Grid>
                {error && <Grid item xs={12}>
                    <RequestErrorMessage
                        error={error}
                        reset={reset}
                    />
                </Grid>}
                <Grid item xs={10}></Grid>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton loading={isPending}
                        disabled={!!error}
                        type="submit" variant="contained" >Сохранить</LoadingButton>
                </Grid>
            </Grid>
        </Box>
    </ContentLayout>
}
import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import apiClient, { queryClient } from "../../api/http-common";
import { useQuery } from "@tanstack/react-query";
import dayjs from 'dayjs';
import { ContentLayout } from "../../Components/ContentLayout/ContentLayout";
import { formateDateToServer, formateDayMoShort, formateDayYear, setTimeZero } from "../../utils/utils";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { BarChart } from '@mui/x-charts/BarChart';

export const useUsersVolumeChart = (start, end) => {
    const { data, isFetching } = useQuery(
        {
            queryKey: ['charts/volume-users', { start, end }],
            queryFn: async () => {
                return await apiClient.get(`charts/volume-users`, {
                    params: {
                        start, end
                    }
                });

            },
        }
    );
    const { values = [], ...meta } = data?.data || {}
    return { values, meta, isFetching }
}

export const useRestVolumeChart = (start, end) => {
    const { data, isFetching } = useQuery(
        {
            queryKey: ['charts/volume-rests', { start, end }],
            queryFn: async () => {
                return await apiClient.get(`charts/volume-rests`, {
                    params: {
                        start, end
                    }
                });

            },
        }
    );
    const { values = [], ...meta } = data?.data || {}
    return { values, meta, isFetching }
}


function BarUsers({ start, end }) {
    // console.log('BarUsers start, end', start, end)
    const { isFetching, values, meta } = useUsersVolumeChart(formateDateToServer(start), formateDateToServer(end))
    // console.log('values', values)

    if (isFetching && !values.length) return null;
    const seriesData = values.map(v => parseInt(v.volume))
    const xData = values.map(v => (v.displayName))
    return <BarChart
        grid={{ horizontal: true, vertical: true }}
        margin={{ bottom: 80 }}
        bottomAxis={{

            labelStyle: {
                fontSize: 14,
                // transform: 'translateY(30px)',
                // transform: `translateY(${
                //     // Hack that should be added in the lib latter.
                //     5 * Math.abs(Math.sin((Math.PI * 45) / 180))
                //     }px)`
            },
            tickLabelStyle: {
                angle: 45,
                textAnchor: 'start',
                fontSize: 12,
            },
        }}
        xAxis={[
            {
                id: 'barCategories',
                data: xData,
                scaleType: 'band',
                valueFormatter: (displayName, context) => {
                    const [last_name, first_name, middle_name] = displayName.split(' ')
                    const shortName = last_name + ' ' + first_name[0] + '. ' + (middle_name?.[0] ? (middle_name?.[0] + '.') : '')
                    return context.location === 'tick'
                        ? shortName.length > 13 ? `${shortName.slice(0, 12).trim()}...` : shortName
                        : displayName
                }
            },
        ]}
        series={[
            {
                data: seriesData,
                // color: '#bfdbf7'
            },
        ]}
    // width={500}
    // height={300}
    />
}

function BarRests({ start, end }) {
    const { isFetching, values, meta } = useRestVolumeChart(formateDateToServer(start), formateDateToServer(end))
    console.log('values', values)

    if (isFetching && !values.length) return null;
    const seriesData = values.map(v => parseInt(v.volume))
    const xData = values.map(v => (v.name))
    return <BarChart
        grid={{ horizontal: true, vertical: true }}
        margin={{ bottom: 80 }}
        bottomAxis={{
            labelStyle: {
                fontSize: 14,
                // transform: `translateY(${
                //     // Hack that should be added in the lib latter.
                //     5 * Math.abs(Math.sin((Math.PI * 45) / 180))
                //     }px)`
            },
            tickLabelStyle: {
                angle: 45,
                textAnchor: 'start',
                fontSize: 12,
            },
        }}
        xAxis={[
            {
                id: 'barCategories',
                data: xData,
                scaleType: 'band',
                valueFormatter: (name, context) => {
                    return context.location === 'tick'
                        ? name.length > 10 ? `${name.slice(0, 10)}...` : name
                        : name
                }
            },
        ]}
        series={[
            {
                data: seriesData,
            },
        ]}
    // width={500}
    // height={300}
    />
}

const DateRangeSelector = ({ start, end, onChange = () => { } }) => {
    const changeStart = (value) => {
        onChange([value, end])
    }
    const changeEnd = (value) => {
        onChange([start, value])
    }
    return <Stack spacing={3} direction={'row'}>
        <DatePicker label="Начало" value={start} onChange={changeStart} />
        <DatePicker label="Конец" minDate={start} value={end} onChange={changeEnd} />
    </Stack>
}

const currentDate = setTimeZero(dayjs())
const startMonth = setTimeZero(dayjs().date(1))
const endMonth = setTimeZero(dayjs().date(currentDate.daysInMonth()))

const UsersChart = ({ }) => {
    const [[start, end], onChange] = useState([startMonth, endMonth])
    // const [start, end] = range
    // console.log('BarUsers start, end', start, end)
    return <Box sx={{ width: '100%' }}>
        <DateRangeSelector
            start={start}
            end={end}
            onChange={onChange}
        />

        <Box sx={{ width: '100%', height: '70vh' }}>
            <BarUsers start={start} end={end} />
        </Box>
    </Box>
}

const RestChart = ({ }) => {
    const [[start, end], onChange] = useState([startMonth, endMonth])
    // const [start, end] = range
    // console.log('BarUsers start, end', start, end)
    return <Box sx={{ width: '100%' }}>
        <DateRangeSelector
            start={start}
            end={end}
            onChange={onChange}
        />

        <Box sx={{ width: '100%', height: '70vh' }}>
            <BarRests start={start} end={end} />
        </Box>
    </Box>
}

export const HomePage = ({ className, ...props }) => {
    return <ContentLayout>
        <Stack spacing={3}>
            <Typography variant="h5">Пролив сотрудников</Typography>
            <UsersChart />

            <Typography variant="h5">Пролив заведений</Typography>
            <RestChart />
        </Stack>
    </ContentLayout>
}
import React, { useEffect, useMemo, useState } from "react";
import { ContentLayout } from '../../Components/ContentLayout/ContentLayout'
import { Link, useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { useUserView } from '../../api/user/useUserView'
import { YandexMap } from "../../Components/YandexMap/YandexMap";
import { Preloader } from "../../ui/Preloader/Preloader";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { BannedTable } from "../../Components/BannedTable/BannedTable";
import EditIcon from '@mui/icons-material/Edit';
import { useSetSpeedDial } from "../../utils/useSpeedDial";
import { useScheduleByUser } from "../../api/users-schedule/useScheduleByUser";
import { UserSchedule } from "./Schedule/UserSchedule";
import { Profile } from "./Profile/Profile";
import { UserWorkshifts } from "./UserWorkshifts/UserWorkshifts";
import { useSetTitle } from "../../utils/useTitle";
import { useFilesByUser } from "../../api/users-personal-files/useFilesByUser";
import { UserFilesList } from "./UserFilesList/UserFilesList";




const UserFilesListLoader = ({ user_id }) => {
    const { data, isError, isFetching } = useFilesByUser(user_id)
    if (isError) return <>Error</>
    if (isFetching && !data?.data) return <Preloader />
    const { values } = data?.data || {}
    return <UserFilesList values={values} user_id={user_id} />
}

const UserScheduleLoader = ({ user_id }) => {
    const { data, isFetching, isError } = useScheduleByUser(user_id)
    if (isError) return <>Error</>
    if (isFetching && !data?.data) return <Preloader />
    const { particulars, periodicities } = data?.data || {}
    return <UserSchedule user_id={user_id} particulars={particulars} periodicities={periodicities} />
}


export const UsersViewContent = ({ value, id }) => {

    const [tab, setTab] = useState('profile');
    const tabs = [
        { value: 'profile', label: 'Профиль' },
        { value: 'schedule', label: 'Расписание' },
        { value: 'workshifts', label: 'Смены' },
        { value: 'files', label: 'Докумены' },
        { value: 'ban', label: 'Блокировки' },
        (value.latitude && value.longitude) && { value: 'map', label: 'Карта' },
    ].filter(Boolean)

    const sdActions = useMemo(() => {
        return [
            { LinkComponent: Link, to: `/users/update/${value.id}`, icon: <EditIcon />, name: 'u-w/edit', tooltipTitle: 'Редактировать сотрудника', key: 'u-w/edit' },
        ]
    }, [])


    useSetSpeedDial({ key: 'users/view', values: sdActions })

    useSetTitle(value.displayName)

    useEffect(() => {
        setTab(tabs[0]?.value)
    }, [id])



    return <ContentLayout
        title={
            <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={tab}
                onChange={(_, v) => setTab(v)}>
                {tabs.map(item => <Tab key={item.value} {...item} />)}
            </Tabs>
            // <Stack direction={'row'} alignItems={'center'} spacing={3}>
            //     <Avatar
            //         alt={value?.displayName}
            //         src={VARIABLES.FILES_URL + `${value?.avatar?.file}`}
            //     />
            //     <Typography variant="h6" component="h1" fontWeight={'bold'} gutterBottom>{value?.displayName}</Typography>
            // </Stack>
        }
    >
        <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            {/* <Grid item xs={12}>
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={tab}
                    onChange={(_, v) => setTab(v)}>
                    {tabs.map(item => <Tab key={item.value} {...item} />)}
                </Tabs>
            </Grid> */}

            <Grid item xs={12}>
                {tab === 'map' && <YandexMap
                    height={'60vh'}
                    key={`view_u_${value?.id}`}
                    viewOnly
                    place={value.latitude && value.longitude && [value.longitude, value.latitude]}
                />}

                {tab === 'workshifts' && <UserWorkshifts user_id={id} />}

                {tab === 'ban' && <BannedTable user_id={id} />}

                {tab === 'schedule' && <UserScheduleLoader user_id={id} />}

                {tab === 'profile' && <Profile value={value} />}

                {tab === 'files' && <UserFilesListLoader user_id={id} />}
            </Grid>

        </Grid>
    </ContentLayout>
}

export const UsersViewPage = ({ className, ...props }) => {
    const { id } = useParams()
    const { value, isFetching } = useUserView(id)

    if (isFetching) return <ContentLayout
        title={<Preloader />}
    />
    if (!value && !isFetching) return <ContentLayout
        title={`404`}
    />

    return <UsersViewContent value={value} id={id} />
}
import apiClient from "../http-common";
import { useQuery } from "@tanstack/react-query";
import {
    useLocation,
    useParams,
    useSearchParams
} from "react-router-dom";

export const useCommentsList = (rest_id, page = 0) => {
    const { data, isFetching } = useQuery(
        {
            queryKey: ['rest-comments/index', { id: rest_id, page }],
            queryFn: async () => {
                return await apiClient.get(`rest-comments/index`, {
                    params: {
                        rest_id,
                        page
                        // page: query.get("page")
                    }
                });

            },
            staleTime: 3000000
        }
    );
    const { values = [], ...meta } = data?.data || {}
    return { values, meta, isFetching }
}
import React, { } from "react";
import Grid from '@mui/material/Grid';
import { mapParticularsToServer, mapPeriodicitiesToServer } from "./mappers";
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSendSchedule } from "../../../api/users-schedule/useSendSchedule";
import { UserScheduleSchedule } from "./UserScheduleSchedule";
import { UserScheduleDates } from "./UserScheduleDates";
import { useSchedule } from "./useSchedule";
import { Stack } from "@mui/material";


export const UserSchedule = ({ user_id, particulars, periodicities }) => {

    const {
        dates,
        isChanged,
        schedule,
        setDates,
        setSchedule,
        particularsMapped,
        periodicitiesMapped
    } = useSchedule({ particulars, periodicities })

    const { mutate, isPending } = useSendSchedule(user_id, {
        onSuccess: () => {

        }
    })

    return <Grid container spacing={3}
        component={'form'}
        action="#"
        method="POST"
        onSubmit={(e) => {
            e.preventDefault()

            const particular = mapParticularsToServer(dates)
            const periodicity = mapPeriodicitiesToServer(schedule)

            mutate({ periodicity, particular })
        }}
    >
        <Grid item xs={12} sm={5}>
            <UserScheduleDates
                dates={dates}
                setDates={setDates}
                schedule={schedule}
            />
        </Grid>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={5}>
            <UserScheduleSchedule
                setSchedule={setSchedule}
                schedule={schedule}
            />
        </Grid>

        <Grid item xs={12}></Grid>

        <Grid item xs={12}>
            <Stack justifyContent={'space-between'} direction={'row'}>
                <Button
                    variant="outlined"
                    color="error"
                    disabled={!isChanged}
                    onClick={() => {
                        setDates(particularsMapped)
                        setSchedule(periodicitiesMapped)
                    }}
                >Отмена</Button>

                <LoadingButton
                    loading={isPending}
                    disabled={!isChanged}
                    type="submit" variant="contained" >Сохранить</LoadingButton>
            </Stack>
        </Grid>
    </Grid>
}
import React, { } from "react";
import { FileUploader } from "../../../Components/FileUploader/FileUploader";
import { useAddUserPersonalFile } from "../../../api/users-personal-files/useAddUserPersonalFile";


export const UserFilesUploader = ({ user_id }) => {
    const { mutateAsync } = useAddUserPersonalFile(user_id, {
        onSuccess: () => {

        }
    })
    return <FileUploader
        text={"Загрузить"}
        privateFile
        fullWidth={false}
        onSuccess={(result) => {
            mutateAsync({
                file_id: result.data.file.id,
                type: 'info'
            })
        }}
    />
}
import { useMemo, useState } from 'react'
import { mapParticularsFromServer, mapParticularsToServer, mapPeriodicitiesFromServer, mapPeriodicitiesToServer } from './mappers';
import { defaultPeriodicities } from './variables';
import { isEqual } from 'lodash';

export const useSchedule = ({
    particulars,
    periodicities
}) => {
    
    const { particularsMapped, periodicitiesMapped } = useMemo(() => {
        const particularsMapped = mapParticularsFromServer(particulars)
        const periodicitiesMapped = {
            ...defaultPeriodicities,
            ...mapPeriodicitiesFromServer(periodicities)
        }
        return ({
            particularsMapped,
            periodicitiesMapped
        })
    }, [particulars, periodicities])

    const [dates, setDates] = useState(particularsMapped)//particulars

    const [schedule, setSchedule] = useState(periodicitiesMapped)

    const isChanged = useMemo(() => !isEqual(
        [mapParticularsToServer(dates), mapPeriodicitiesToServer(schedule)],
        [mapParticularsToServer(particularsMapped), mapPeriodicitiesToServer(periodicitiesMapped)])
    , [particularsMapped, periodicitiesMapped, dates, schedule])


    return {
        particularsMapped,
        periodicitiesMapped,
        dates,
        setDates,
        schedule,
        setSchedule,
        isChanged
    }
}
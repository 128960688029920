import apiClient, { queryClient } from "../http-common";
import { useMutation } from "@tanstack/react-query";

export const useDeleteComment = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: ['rest-comments/delete'],
        mutationFn: async (id) => {
            return await apiClient.delete("rest-comments/delete", { params: { id } });
        },
        onSuccess: (res) => {
            if (res.data.error) {
                return onSuccess(res)
            }
            queryClient.invalidateQueries({ queryKey: ['rest-comments/index'] })
            onSuccess(res)
        },
        onError,
    })
}
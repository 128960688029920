import apiClient from "../http-common";
import { useQuery } from "@tanstack/react-query";
import { useSetProgressBar } from '../../utils/useProgressBar'

export const useRestaurantView = (id) => {
    // const [value, setValue] = useState(null)

    const { isFetching: isLoading, refetch, data } = useQuery({
        queryKey: ["/restaurants/view", { id }],
        queryFn: async () => {
            return await apiClient.get("/restaurants/view", { params: { id } });
        },
        staleTime: 3000000
    });
    useSetProgressBar(isLoading)

    return {
        value: data?.data, isLoading
    }
}
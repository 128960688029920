import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MuiLink from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import { VARIABLES } from '../../utils/variables';

export const RestaurantTableRow = ({
    id,
    avatar,
    name,
    actions,
    address
}) => {
    return <TableRow>
        <TableCell>
            <MuiLink component={Link} to={`/restaurants/view/${id}`}>
                <Avatar
                    alt={name}
                    src={VARIABLES.FILES_URL + `${avatar?.file}`} />
            </MuiLink>
        </TableCell>
        <TableCell><MuiLink component={Link} to={`/restaurants/view/${id}`}>{name}</MuiLink></TableCell>
        <TableCell>{address}</TableCell>
        <TableCell align="right">
            {actions}
            <ActionToEditRestaurant id={id} />

            {/* <button onClick={() => { deleteRest(value.id) }}>delete</button> */}
        </TableCell>
    </TableRow>
}

export const ActionToEditRestaurant = ({ id }) => {
    return <IconButton LinkComponent={Link} to={`/restaurants/update/${id}`}><EditIcon /></IconButton>
}

export const RestaurantTableHead = ({ actions }) => {
    return <TableRow>
        <TableCell>Фото</TableCell>
        <TableCell>Название</TableCell>
        <TableCell>Адрес</TableCell>
        {actions && <TableCell align="right">Действия</TableCell>}
    </TableRow>
}
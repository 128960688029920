import React, { } from "react";
import { Stack } from "@mui/material";
import { ItemsList } from "../../../Components/ItemsList/ItemsList";
import { UserFilesUploader } from "./UserFilesUploader";
import { UserFileItem } from "./UserFileItem";



export const UserFilesList = ({ values, user_id }) => {
    return <Stack spacing={3}>
        <UserFilesUploader user_id={user_id} />

        <ItemsList
            emptyText={'Файлы отсутствуют'}
            values={values}
            elementsMapper={({ file, relatation }) => <UserFileItem
                key={relatation.id}
                relatation={relatation}
                file={file}
            />}
        />
    </Stack>
}

import React, { } from "react";
import Grid from '@mui/material/Grid';
import { isAvailable } from "./utils";
import { DateSelector } from "./DateSelector";
import { TimeRangeSlider } from "./TimeRangeSlider";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TimeInformation } from "./TimeInformation";
import Typography from '@mui/material/Typography';
import { useParticularsSchedule } from "./useParticularsSchedule";

export const UserScheduleDates = ({
    dates,
    setDates,
    schedule
}) => {
    const {
        currentDay,
        currentRange,
        date,
        handleDatesChecked,
        handleDatesRange,
        setDate
    } = useParticularsSchedule({
        dates,
        schedule,
        setDates
    })

    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <DateSelector
                onChange={setDate}
                value={date}
                workDates={dates}
                workSchedule={schedule}
            />
        </Grid>
        <Grid item xs={12}>
            <FormControlLabel control={<Switch
                checked={isAvailable(dates, date, schedule)}
                onChange={(event) => {
                    handleDatesChecked(event.target.checked);
                }}
            />} label="Рабочий" />
        </Grid>
        <Grid item sx={12}>
            <Typography component="span" variant="body1" color="text.secondary">
                Допустимое время начала и завершения смены в выбраную дату
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <TimeRangeSlider
                disabled={!isAvailable(dates, date, schedule)}
                day={currentDay}
                value={currentRange}
                onChange={handleDatesRange}
            />
        </Grid>

        <Grid item xs={12}>
            <TimeInformation
                currentDay={currentDay}
                currentRange={currentRange}
            />
        </Grid>
    </Grid>
}
import apiClient, { queryClient } from "../http-common";
import { useMutation } from "@tanstack/react-query";


export const useUpdateUser = (id, { onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: "user/update",
        mutationFn: async (value) => {
            return await apiClient.put("user/update", value, { params: { id } });
        },
        onSuccess: (res) => {
            queryClient.invalidateQueries({ queryKey: ['user'] })
            queryClient.invalidateQueries({ queryKey: [`/user/view`, { id }] })
            onSuccess(res)

        },
        onError: (err) => {
            onError(err)
        }
    })
}
import React, { } from "react";
import { Stack } from "@mui/material";
import { ItemsList } from "../../../Components/ItemsList/ItemsList";
import { UsersFilesUploader } from "./UsersFilesUploader";
import { UsersFileItem } from "./UsersFileItem";



export const UsersFilesList = ({ values }) => {
    return <Stack spacing={3}>
        <UsersFilesUploader />

        <ItemsList
            emptyText={'Файлы отсутствуют'}
            values={values}
            elementsMapper={({ file, relatation }) => <UsersFileItem
                key={relatation.id}
                relatation={relatation}
                file={file}
            />}
        />
    </Stack>
}

import React, {  } from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

export const BaseMenuListItem = ({
    listItemButtonProps,
    listItemTextProps,
    icon
}) => {
    return <ListItem disablePadding>
        <ListItemButton {...listItemButtonProps}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText {...listItemTextProps} />
        </ListItemButton>
    </ListItem>
}
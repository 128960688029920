import AttachFileIcon from '@mui/icons-material/AttachFile';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MuiLink from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import React, { useState } from 'react'
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ListItemButton from '@mui/material/ListItemButton';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import apiClient, { queryClient } from "../../api/http-common";




export const BaseFileItem = ({ primary, secondary, secondaryAction, href, is_private }) => {

    const dowloadPrivate = () => {
        apiClient.get(href, {
            responseType: 'blob'
        }).then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', href); //or any other extension
            document.body.appendChild(link);
            link.click();
            
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }
    return <>
        <ListItem
            dense
            alignItems="flex-start"
            secondaryAction={secondaryAction}
        >
            {/* <ListItemButton
                LinkComponent={MuiLink}
                download
                href={href}
            > */}
            <ListItemIcon>
                <Tooltip disableFocusListener placement='top' title={'Скачать файл'}>
                    {is_private ?
                        <IconButton
                            onClick={dowloadPrivate}
                            edge="end">
                            <FileDownloadIcon />
                        </IconButton> :
                        <IconButton
                            LinkComponent={MuiLink}
                            download
                            href={href}
                            edge="end">
                            <FileDownloadIcon />
                        </IconButton>}
                </Tooltip>
            </ListItemIcon>
            <ListItemText
                sx={{ '& .MuiListItemText-primary': { overflow: 'hidden', textOverflow: 'ellipsis' } }}
                primary={primary}
                secondary={secondary}
            />
            {/* </ListItemButton> */}
        </ListItem>
        <Divider />
    </>
}
export const FileItem = ({ id, file, name, extension, mime, secondaryAction, is_private }) => {
    return <BaseFileItem
        secondaryAction={secondaryAction}
        primary={name}
        href={file}
        is_private={is_private}
        secondary={<Typography
            variant="body2"
            fontSize={12}
        >
            {extension}-({mime})
        </Typography>}
    />
}

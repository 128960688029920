import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ContentLayout } from '../../Components/ContentLayout/ContentLayout'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
    useNavigate,
    Link,
    useParams
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useWorkshiftsView } from '../../api/workshift/useWorkshiftsView'
import { PRODUCTS, VARIABLES } from "../../utils/variables";
import { useListWorkshiftResult } from "../../api/workshift-results/useListWorkshiftResult";
import MuiLink from '@mui/material/Link'
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import STATUSES from "../../utils/STATUSES";
import { Preloader } from "../../ui/Preloader/Preloader";
import List from '@mui/material/List';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useChangeResultStatus } from "../../api/workshift-results/useChangeResultStatus";
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useDeleteResult } from "../../api/workshift-results/useDeleteResult";
import { useModal } from "../../utils/useModal";
import { FileUploader } from "../../Components/FileUploader/FileUploader";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useWorkshiftsResultUpdate } from "../../api/workshift-results/useWorkshiftsResultUpdate";
import { ItemsList } from "../../Components/ItemsList/ItemsList";
import { BaseFileItem } from "../../ui/FileItem/FileItem";
import { useFilesByWorkshift } from "../../api/workshift-files/useFilesByWorkshift";
import { useCreateWorkshiftResult } from "../../api/workshift-results/useCreateWorkshiftResult";
import { useAddWorkshiftFile } from "../../api/workshift-files/useAddWorkshiftFile";
import { useWorkshiftsResolve } from "../../api/workshift/useWorkshiftsResolve";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TableLayout } from "../../Components/Table/TableLayout";
import { WorkshiftTableHead, WorkshiftTableRow } from "../../Components/Table/WorkshiftTable";
import SchoolIcon from '@mui/icons-material/School';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { formateDateRU } from "../../utils/utils";
import Rating from '@mui/material/Rating';
import { useSetTitle } from "../../utils/useTitle";
import { useSetSpeedDial } from "../../utils/useSpeedDial";
import { useDeleteWorkshiftFile } from "../../api/workshift-files/useDeleteWorkshiftFile";
import {
    TransformWrapper,
    TransformComponent,
    useControls,
    useTransformContext,
    useTransformEffect,
} from "react-zoom-pan-pinch";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import CloseIcon from '@mui/icons-material/Close';
import { useWorkshiftsReject } from "../../api/workshift/useWorkshiftsReject";
import { useDeleteAllResult } from "../../api/workshift-results/useDeleteAllResult";

const DeleteResultAction = ({ id }) => {
    const { isPending, mutate } = useDeleteResult()
    return <Tooltip disableFocusListener title={'Удалить'}>
        <IconButton
            color="error"
            disabled={isPending}
            onClick={() => {
                if (window.confirm('Удалить отчет?')) {
                    mutate(id)
                }
            }}
            edge="end" aria-label="delete">
            <DeleteIcon />
        </IconButton>
    </Tooltip>
}
const DeleteAllResultAction = ({ ws_id }) => {
    const { isPending, mutate } = useDeleteAllResult()
    return <Tooltip disableFocusListener title={'Удалить все'}>
        <IconButton
            color="error"
            disabled={isPending}
            onClick={() => {
                if (window.confirm('Удалить все отчеты?')) {
                    mutate(ws_id)
                }
            }}
            edge="end" aria-label="delete">
            <DeleteIcon />
        </IconButton>
    </Tooltip>
}

const ResolveAllResultAction = ({ ws_id }) => {
    const { isPending, mutate } = useWorkshiftsResolve()
    return <Tooltip disableFocusListener title={'Подтвердить все'}>
        <IconButton
            color="success"
            disabled={isPending}
            onClick={() => {
                if (window.confirm('Подтвердить все отчеты?')) {
                    mutate(ws_id)
                }
            }}
            edge="end" aria-label="delete">
            <CheckCircleOutlineIcon />
        </IconButton>
    </Tooltip>
}


const ResultForm = ({ discounted_amount, volume, product, file, amount, comment }) => {
    return <Grid container spacing={3} sx={{ my: 1 }}>

        <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel id="product-select-label">Напиток</InputLabel>
                <Select
                    // error={empty['product']}
                    labelId="product-select-label"
                    id="product"
                    label="Напиток"
                    name={'product'}
                    defaultValue={product}
                >
                    {Object.entries(PRODUCTS).map(([v, c]) => <MenuItem key={v} value={v}>{c}</MenuItem>)}
                </Select>
            </FormControl>
        </Grid>

        <Grid item xs={12}>
            <TextField
                // error={empty['volume']}
                type='number'
                label="Объем"
                name="volume"
                id="volume"
                fullWidth
                defaultValue={volume}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                // error={empty['amount']}
                type='number'
                label="Сумма за напиток"
                name="amount"
                id="amount"
                fullWidth
                defaultValue={amount}
            />
        </Grid>
        {/* <Grid item xs={12}>
            <TextField
                // error={empty['discounted_amount']}
                type='number'
                label="Сумма после скидки"
                name="discounted_amount"
                id="discounted_amount"
                fullWidth
                defaultValue={discounted_amount}
            />
        </Grid> */}
        <Grid item xs={12}>
            <TextField
                multiline
                label="Комментарий(необязательно)"
                name="comment"
                id="comment"
                fullWidth
                defaultValue={comment}
            />
        </Grid>
        {/* <Grid item xs={12}>
            <FileUploader
                defaultFile={{ file }}
            // error={empty['file_id']} 
            />
        </Grid> */}
    </Grid>
}

const DialogBody = ({
    boxProps,
    handleClose,
    isPending,
    children,
    title
}) => {
    return <Box
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        {...boxProps}
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>

            {children}

        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Отмена</Button>
            <LoadingButton
                loading={isPending}
                variant="contained" type='submit' >
                Сохранить
            </LoadingButton>
        </DialogActions>
    </Box>
}

const EditResultDialog = ({ handleClose, id, discounted_amount, volume, product, file, amount, comment, ...props }) => {
    const { mutateAsync, isPending } = useWorkshiftsResultUpdate(id)
    return <DialogBody
        isPending={isPending}
        title={'Изменить результат'}
        handleClose={handleClose}
        boxProps={{
            component: 'form',
            onSubmit: async (e) => {
                e.preventDefault()
                const data = new FormData(e.currentTarget);
                const v = {
                    ...[...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {}),
                }
                try {
                    const r = await mutateAsync(v)
                    handleClose()
                } catch (error) {
                    console.log('error change password', error)
                }
            }
        }}
    >
        <ResultForm
            discounted_amount={discounted_amount}
            volume={volume}
            product={product}
            file={file}
            comment={comment}
            amount={amount} />
    </DialogBody >
}



const RejectResultForm = ({ handleClose, id }) => {
    const { isPending, mutateAsync } = useChangeResultStatus()
    return <RejectResultComponent
        handleClose={handleClose}
        title={'Отклонить результат'}
        isPending={isPending}
        mutateAsync={async (formValues) => await mutateAsync({ ...formValues, id, status: 2 })}
    />
}
const RejectAllResultForm = ({ handleClose, ws_id }) => {
    const { isPending, mutateAsync } = useWorkshiftsReject()
    return <RejectResultComponent
        handleClose={handleClose}
        title={'Отклонить все результаты'}
        isPending={isPending}
        mutateAsync={async (formValues) => await mutateAsync({ ...formValues, id: ws_id })}
    />
}
const RejectResultComponent = ({ handleClose, title, isPending, mutateAsync }) => {
    return <DialogBody
        isPending={isPending}
        title={title}
        handleClose={handleClose}
        boxProps={{
            component: 'form',
            onSubmit: async (e) => {
                e.preventDefault()
                const data = new FormData(e.currentTarget);
                const formValues = {
                    ...[...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {}),
                }
                try {
                    const r = await mutateAsync(formValues)
                    handleClose()
                } catch (error) {
                    console.log('error change password', error)
                }
            }
        }}
    >
        <Grid container spacing={3} sx={{ my: 1 }}>
            <Grid item xs={12}>
                <TextField
                    // error={empty['volume']}
                    multiline
                    label="Причина отклонения"
                    name="reject_reason"
                    id="reject_reason"
                    fullWidth
                />
            </Grid>
        </Grid>
    </DialogBody >
}

const RejectResultButton = ({ id, status }) => {
    // const { isPending, mutate } = useChangeResultStatus()

    const { handleOpen } = useModal({
        Component: RejectResultForm,
        props: {
            id
        }
    }, [id])

    return <Tooltip disableFocusListener title={'Отклонить'}>
        <IconButton
            color="warning"
            disabled={status === 2}
            onClick={handleOpen}
            edge="end" aria-label="delete">
            <DoDisturbOnIcon />
        </IconButton>
    </Tooltip>
}

const RejectAllResultButton = ({ ws_id, disabled }) => {
    // const { isPending, mutate } = useChangeResultStatus()

    const { handleOpen } = useModal({
        Component: RejectAllResultForm,
        props: {
            ws_id
        }
    }, [ws_id])

    return <Tooltip disableFocusListener title={'Отклонить все'}>
        <IconButton
            color="warning"
            disabled={disabled}
            onClick={handleOpen}
            edge="end" aria-label="delete">
            <DoDisturbOnIcon />
        </IconButton>
    </Tooltip>
}

const EditResultButton = ({ id, discounted_amount, volume, product, file, amount, comment }) => {
    const { handleOpen } = useModal({
        Component: EditResultDialog,
        props: {
            id, discounted_amount, volume, product, file, amount, comment
        }
    }, [id, discounted_amount, volume, product, file, amount, comment])

    return <Tooltip disableFocusListener title={'Изменить'}>
        <IconButton
            color="primary"
            onClick={handleOpen}
            edge="end" aria-label="delete">
            <EditIcon />
        </IconButton>
    </Tooltip>
}

const ResultsTableRow = ({ id, discounted_amount, volume, product, file, amount, reject_reason, comment, status, is_empty }) => {
    const { isPending, mutate } = useChangeResultStatus()


    const actions = <Stack
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent="flex-end"
        direction="row"
        spacing={1}>

        {!is_empty && <EditResultButton
            id={id}
            discounted_amount={discounted_amount}
            volume={volume}
            product={product}
            file={file}
            amount={amount}
            comment={comment}
        />}

        <DeleteResultAction id={id} />

        <RejectResultButton status={status} id={id} />

        <Tooltip disableFocusListener title={'Подтвердить'}>
            <IconButton
                color="success"
                disabled={isPending || status === 1}
                onClick={() => {
                    mutate({ id, status: 1 })
                }}
                edge="end" aria-label="delete">
                <CheckCircleOutlineIcon />
            </IconButton>
        </Tooltip>

    </Stack>

    if (is_empty) {
        return <TableRow>
            <TableCell>
                <Tooltip disableFocusListener title={STATUSES.REPORT_TEXT[status] + (reject_reason ? `. ${reject_reason}` : '')}>
                    {STATUSES.REPORT_ICON[status]}
                </Tooltip>
            </TableCell>
            <TableCell colSpan={3} align="center">Без продаж</TableCell>
            <TableCell align="right">{comment}</TableCell>
            <TableCell align="right">
                {actions}
            </TableCell>
        </TableRow>
    }

    return <TableRow>
        <TableCell>
            <Tooltip disableFocusListener title={STATUSES.REPORT_TEXT[status] + (reject_reason ? `. ${reject_reason}` : '')}>
                {STATUSES.REPORT_ICON[status]}
            </Tooltip>
        </TableCell>
        {/* <TableCell align="right">
            <Tooltip disableFocusListener title={'Скачать файл'}>
                <IconButton component={MuiLink} download href={file?.file}>
                    <FileDownloadIcon />
                </IconButton>
            </Tooltip>
        </TableCell> */}
        <TableCell align="right">{PRODUCTS[product]}</TableCell>
        <TableCell align="right">{volume}</TableCell>
        <TableCell align="right">{amount}</TableCell>
        {/* <TableCell align="right">{discounted_amount}</TableCell> */}
        <TableCell align="right">{comment}</TableCell>
        <TableCell align="right">
            {actions}
        </TableCell>
    </TableRow>
}

const TYPE_TEXT_MAP = {
    sales_receipt: 'Чек из заведения',
    commission: 'Чек о комиссии',
}
export const ReceiptItem = ({
    relatation,
    file,

    onView
}) => {
    const [isPending, setPending] = useState(false)
    const { mutate, reset, error } = useDeleteWorkshiftFile()
    return <BaseFileItem
        href={file.file}
        primary={<>{TYPE_TEXT_MAP[relatation.type]} - {formateDateRU(relatation.create_at)}</>}
        secondary={<Typography
            variant="body2"
            fontSize={12}
        >
            {file.name}
        </Typography>}
        secondaryAction={
            <Stack
                divider={<Divider orientation="vertical" flexItem />}
                justifyContent="flex-end"
                direction="row"
                spacing={1}
            >
                {onView && <Tooltip disableFocusListener placement='top' title={'Просмотреть файл'}>
                    <IconButton
                        onClick={onView}
                        edge="end">
                        <RemoveRedEyeIcon />
                    </IconButton>
                </Tooltip>}
                <Tooltip disableFocusListener placement='top' title={'Удалить файл'}>
                    <IconButton
                        disabled={isPending}
                        onClick={() => {
                            if (window.confirm('Удалить файл?')) {
                                setPending(true)
                                mutate(relatation.id)
                            }
                        }}
                        edge="end" aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        }
    />
}
export const CommissionReportLoader = ({ ws_id, commission }) => {
    const { values, meta, isFetching } = useFilesByWorkshift(ws_id, ['sales_receipt', 'commission'])
    if (!values?.length && isFetching) return <Preloader />
    return <CommissionReport ws_id={ws_id} commission={commission} values={values} />
}

const round2 = (value) => Math.round(value * 100) / 100
const LightBoxControls = ({ handleClose, name }) => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    const [disableOut, setDisableOut] = useState(false)
    const [disableIn, setDisableIn] = useState(false)
    // console.log('disableOut', disableOut)
    useTransformEffect(({ state, instance }) => {
        // console.log('useTransformEffect', state, instance); // { previousScale: 1, scale: 1, positionX: 0, positionY: 0 }
        // console.log('useTransformEffect', round2(state.scale), round2(instance.props.maxScale)); // { previousScale: 1, scale: 1, positionX: 0, positionY: 0 }
        setDisableOut(round2(state.scale) === round2(instance.props.minScale))
        setDisableIn(round2(state.scale) === round2(instance.props.maxScale))
        return () => {
            // unmount
        };
    });
    return (
        <Stack
            sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                width: '100%',
                zIndex: 1,
                pl: 1,
                pr: 1
            }}
            divider={<Divider orientation="vertical" flexItem />}
            justifyContent="flex-end"
            direction="row"
            alignItems={'center'}
            spacing={1}
        >
            {/* <Box flexGrow={1} /> */}

            <Typography>{name}</Typography>

            <Box flexGrow={1} />

            <Tooltip disableFocusListener placement='top' title={'Увеличить'}>
                <IconButton disabled={disableIn} onClick={() => zoomIn()}><ZoomInIcon /></IconButton>
            </Tooltip>

            <Tooltip disableFocusListener placement='top' title={'Уменьшить'}>
                <IconButton disabled={disableOut} onClick={() => zoomOut()}><ZoomOutIcon /></IconButton>
            </Tooltip>

            <Tooltip disableFocusListener placement='top' title={'Нормальный размер'}>
                <IconButton disabled={disableOut} onClick={() => resetTransform()}><ZoomInMapIcon /></IconButton>
            </Tooltip>

            <Tooltip disableFocusListener placement='top' title={'Закрыть'}>
                <IconButton onClick={handleClose}><CloseIcon /></IconButton>
            </Tooltip>
        </Stack>
    );
};
const LightBox = ({ handleClose, src, name }) => {
    const scaleUp = true;
    const zoomFactor = 8;

    const [imageNaturalWidth, setImageNaturalWidth] = useState(0);
    const [imageNaturalHeight, setImageNaturalHeight] = useState(0);

    const [container, setContainer] = useState(null);

    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);

    const imageScale = useMemo(() => {
        if (
            containerWidth === 0 ||
            containerHeight === 0 ||
            imageNaturalWidth === 0 ||
            imageNaturalHeight === 0
        )
            return 0;
        const scale = Math.min(
            containerWidth / imageNaturalWidth,
            containerHeight / imageNaturalHeight,
        );
        return scaleUp ? scale : Math.max(scale, 1);
    }, [
        scaleUp,
        containerWidth,
        containerHeight,
        imageNaturalWidth,
        imageNaturalHeight,
    ]);

    const handleResize = useCallback(() => {
        if (container !== null) {
            const rect = container.getBoundingClientRect();
            setContainerWidth(rect.width);
            setContainerHeight(rect.height - 50);
        } else {
            setContainerWidth(0);
            setContainerHeight(0);
        }
    }, [container]);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    const handleImageOnLoad = (image) => {
        setImageNaturalWidth(image.naturalWidth);
        setImageNaturalHeight(image.naturalHeight);
        // handleResize()
    };

    useEffect(() => {
        const image = new Image();
        image.onload = () => handleImageOnLoad(image);
        image.src = src;
    }, [src]);

    return <Box
        ref={(el) => setContainer(el)}
        sx={{
            width: "100%",
            height: "85vh",
            padding: 1,
            paddingTop: '40px'
        }}
    >

        {imageScale > 0 && <TransformWrapper
            key={`${containerWidth}x${containerHeight}`}
            centerOnInit
            initialScale={imageScale}
            minScale={imageScale}
            maxScale={imageScale * zoomFactor}
        >
            <LightBoxControls name={name} handleClose={handleClose} />
            <TransformComponent
                wrapperStyle={{
                    width: "100%",
                    height: "100%"//"calc(100% - 37px)",
                }}
            >
                <img src={src} />
            </TransformComponent>
        </TransformWrapper>}

    </Box >
}

export const CommissionReport = ({ ws_id, commission, values }) => {
    const { handleOpen } = useModal({
        Component: LightBox,
        props: {},
        DialogProps: {
            scroll: 'paper',
            maxWidth: 'xl'
        }
    }, [])
    return <ItemsList
        emptyText={'Чеки отсутствуют'}
        values={values}
        elementsMapper={({ file, ...relatation }) => <ReceiptItem
            key={relatation.id}
            relatation={relatation}
            file={file}
            onView={file.mime.startsWith('image') ? () => handleOpen({
                src: file.file,
                name: `${TYPE_TEXT_MAP[relatation.type]} - ${formateDateRU(relatation.create_at)}`
            }) : undefined}
        />}
    />
}

export const CommissionReportForm = ({ ws_id }) => {
    const { mutateAsync, isPending, error, reset } = useAddWorkshiftFile(ws_id)
    return <Grid
        container
        spacing={2}>
        <Grid item xs={12} sm={6}>
            <FileUploader
                helpText="Загрузите фото чека"
                text={"Чек из банка"}
                onSuccess={(result) => {
                    mutateAsync({
                        file_id: result.data.file.id,
                        type: 'commission' //'sales_receipt'
                    })
                }}
            // error={empty['file_id']} 
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <FileUploader
                helpText="Загрузите фото чека"
                text="Товарный чек"
                onSuccess={(result) => {
                    mutateAsync({
                        file_id: result.data.file.id,
                        type: 'sales_receipt' //'sales_receipt'
                    })
                }}
            // error={empty['file_id']} 
            />
        </Grid>
    </Grid>
}

const calculateCommission = (values, commission, base_commission) => {
    const { amount, volume, discounted_amount } = values.reduce((acc, cur) => ({
        amount: acc.amount + cur.amount,
        volume: acc.volume + cur.volume,
        discounted_amount: acc.discounted_amount + cur.discounted_amount,
    }), { amount: 0, volume: 0, discounted_amount: 0 })


    const totalCommission = amount * (commission / 100)
    const isMinimalCommission = base_commission > totalCommission
    const finallyCommission = isMinimalCommission ? base_commission : totalCommission
    return {
        totalCommission,
        isMinimalCommission,
        finallyCommission,
        amount,
        volume,
        discounted_amount
    }
}
const ResultsTableSummary = ({ ws_id, values, commission, base_commission }) => {
    const { amount,
        finallyCommission,
        isMinimalCommission,
        volume } = calculateCommission(values, commission, base_commission)

    return <TableContainer>
        <Table>
            {/* <TableHead>
                <TableRow>
                    <TableCell colSpan={2} align="center" component={'th'}>Сумма</TableCell>
                </TableRow>
            </TableHead> */}
            <TableBody>
                <TableRow>
                    <TableCell component={'th'}>Пролив&nbsp;(мл)</TableCell>
                    <TableCell align="right">{volume === 0 ? 'Нет' : volume}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component={'th'}>Сумма</TableCell>
                    <TableCell align="right">{amount === 0 ? 'Нет' : amount}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component={'th'}>Комиссия</TableCell>
                    <TableCell align="right">
                        {finallyCommission}&nbsp;({isMinimalCommission ? 'минимальная комиссия' : `${commission}%`})
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer >
}

export const ResultsTableLoader = ({ ws_id, commission, base_commission }) => {
    const { isFetching, values, meta } = useListWorkshiftResult(ws_id)
    if (!values?.length && isFetching) return <Preloader />
    return <ResultsTable
        ws_id={ws_id}
        workshiftResults={values}
        commission={commission}
        base_commission={base_commission}
    />
}

export const ResultsTable = ({ ws_id, workshiftResults, workshiftFiles, commission, base_commission }) => {

    return <Grid
        container
        // item
        spacing={3}>
        <Grid item xs={12}>
            {!!workshiftResults?.length ? <Stack spacing={2}>
                <Typography textAlign={'center'}>Результаты</Typography>
                <Divider flexItem />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Статус</TableCell>
                                <TableCell align="right">Напиток</TableCell>
                                <TableCell align="right">Объем&nbsp;(мл)</TableCell>
                                <TableCell align="right">Сумма за напиток</TableCell>
                                <TableCell align="right">Комментарий</TableCell>
                                <TableCell align="right">Действия</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {workshiftResults.map((row) => <ResultsTableRow key={row.id} {...row} />)}

                            {workshiftResults.length > 1 && <TableRow>
                                <TableCell align="right" colSpan={5}>Действия со всеми результатами</TableCell>
                                <TableCell align="right">
                                    <Stack
                                        divider={<Divider orientation="vertical" flexItem />}
                                        justifyContent="flex-end"
                                        direction="row"
                                        spacing={1}>

                                        <DeleteAllResultAction ws_id={ws_id} />

                                        <RejectAllResultButton ws_id={ws_id} />

                                        <ResolveAllResultAction ws_id={ws_id} />

                                    </Stack>
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer >
            </Stack> : 'Результыты отсутствуют'}
        </Grid>

        <Grid item xs={12}></Grid>

        <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
                <Typography textAlign={'center'}>Чеки</Typography>
                <Divider flexItem />

                {workshiftFiles ?
                    <CommissionReport ws_id={ws_id} values={workshiftFiles} commission={commission} /> :
                    <CommissionReportLoader ws_id={ws_id} commission={commission} />}
                <CommissionReportForm ws_id={ws_id} />
            </Stack>
        </Grid>

        {!!workshiftResults.length && <>

            <Grid item xs={12} sm={6}>
                <Stack spacing={2}>
                    <Typography textAlign={'center'}>Сумма</Typography>
                    <Divider flexItem />

                    <ResultsTableSummary
                        ws_id={ws_id}
                        commission={commission}
                        values={workshiftResults}
                        base_commission={base_commission} />
                </Stack>
            </Grid>
        </>}

        <Grid item xs={12}></Grid>
    </Grid>
}
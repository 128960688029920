import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MuiLink from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import { formateDateRU, formateDateTime, formateDayYear, formateTime } from "../../utils/utils";
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import AddTaskIcon from '@mui/icons-material/AddTask';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { VARIABLES } from '../../utils/variables';
import STATUSES from "../../utils/STATUSES";
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TableSortLabel from '@mui/material/TableSortLabel';
import SchoolIcon from '@mui/icons-material/School';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Container from '@mui/material/Container';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

export const WorkshiftTableRow = ({
    id,
    user,
    restaurant,
    start,
    end,
    description,
    state,
    type,
    no_sales,

    actions
}) => {
    return <TableRow>
        <TableCell>
            <Stack
                divider={<Divider orientation="vertical" flexItem />}
                justifyContent="flex-start"
                direction="row"
                spacing={1}>
                <Tooltip disableFocusListener title={STATUSES.TEXT[state]}>
                    {STATUSES.ICON[state]}
                </Tooltip>

                {type !== 0 && <Tooltip disableFocusListener title={'Стажировка'}>
                    <SchoolIcon color='info' />
                </Tooltip>}

                {no_sales && <Tooltip disableFocusListener title={'Без продаж'}>
                    <MoneyOffIcon color='info' />
                </Tooltip>}
            </Stack>
        </TableCell>
        {/* <TableCell>{description}</TableCell> */}


        <TableCell align="right">
            <MuiLink component={Link} to={`/users/view/${user.id}`}>
                <Chip
                    avatar={<Avatar alt={user.displayName} src={VARIABLES.FILES_URL + `${user.avatar?.file}`} />}
                    label={user.displayName}
                    variant="outlined"
                />
            </MuiLink>
        </TableCell>
        <TableCell align="right">
            <MuiLink component={Link} to={`/restaurants/view/${restaurant.id}`}>
                <Chip
                    avatar={<Avatar alt={restaurant.name} src={VARIABLES.FILES_URL + `${restaurant.avatar?.file}`} />}
                    label={restaurant.name}
                    variant="outlined"
                />
            </MuiLink>
        </TableCell>
        <TableCell align="right">{formateDayYear(start)} {formateTime(start)}</TableCell>
        <TableCell align="right">{formateTime(end)}</TableCell>

        {actions && <TableCell align="right">

            <Stack
                divider={<Divider orientation="vertical" flexItem />}
                justifyContent="flex-end"
                direction="row"
                spacing={1}>
                {actions}
                <ActionToEditWorkshift id={id} />
                <ActionToViewWorkshift id={id} />
            </Stack>
        </TableCell>}

    </TableRow>
}

export const CollapseWorkshiftTableRow = ({
    id,
    user,
    restaurant,
    start,
    end,
    description,
    state,
    type,
    no_sales,
    commission,
    base_commission,

    children,

    actions
}) => {
    const [open, setOpen] = React.useState(false);

    return <>
        <WorkshiftTableRow
            id={id}
            user={user}
            restaurant={restaurant}
            start={start}
            end={end}
            description={description}
            state={state}
            type={type}
            no_sales={no_sales}
            commission={commission}
            base_commission={base_commission}

            actions={[
                !no_sales && <Tooltip disableFocusListener title={'Развернуть результаты'}>
                    <IconButton
                        aria-label="expand row"
                        // size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </Tooltip>,
                actions
            ]}
        />


        {!no_sales && <TableRow>
            <TableCell sx={{ ['&:empty']: { display: 'none' } }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Paper>
                        <Container maxWidth={false} sx={{ mt: '24px' }}>
                            {children}
                        </Container>
                        {/* <ResultsTable ws_id={id} commission={commission} base_commission={base_commission} /> */}

                    </Paper>
                </Collapse>
            </TableCell>
        </TableRow>}
    </>
}

export const ActionToEditWorkshift = ({ id }) => {
    return <Tooltip disableFocusListener title={'Редактировать'}>
        <IconButton LinkComponent={Link} to={`/workshifts/update/${id}`}><EditIcon /></IconButton>
    </Tooltip>
}

export const ActionToViewWorkshift = ({ id }) => {
    return <Tooltip disableFocusListener title={'Просмотреть'}>
        <IconButton LinkComponent={Link} to={`/workshifts/view/${id}`}><VisibilityIcon /></IconButton>
    </Tooltip>
}

export const ActionCancelWorkshift = ({ onClick, disabled }) => {
    return <Tooltip disableFocusListener title={'Отменить'}>
        <IconButton color={'error'} disabled={disabled} onClick={onClick}><CancelIcon /></IconButton>
    </Tooltip>
}

const selectOrderDirection = (direction) => {
    if (!direction) return 'asc'
    if (direction === 'asc') return 'desc'
    return undefined
}

export const WorkshiftTableHead = ({ actions, order = {}, onChangeOrder = () => { } }) => {
    // const order = 'desc'
    // const orderBy = 'user'
    return <TableRow>
        <TableCell>
            <TableSortLabel
                active={!!order.status}
                direction={order.status}
                onClick={() => {
                    onChangeOrder({
                        order: selectOrderDirection(order.status),
                        orderBy: 'status'
                    })
                }}
            >Статус</TableSortLabel>
        </TableCell>
        {/* <TableCell>Описание</TableCell> */}


        <TableCell align="right">
            <TableSortLabel
                active={!!order.user_id}
                direction={order.user_id}
                onClick={() => {
                    onChangeOrder({
                        order: selectOrderDirection(order.user_id),
                        orderBy: 'user_id'
                    })
                }}
            >
                Сотрудник
            </TableSortLabel>
        </TableCell>
        <TableCell align="right">
            <TableSortLabel
                active={!!order.restaurants_id}
                direction={order.restaurants_id}
                onClick={() => {
                    onChangeOrder({
                        order: selectOrderDirection(order.restaurants_id),
                        orderBy: 'restaurants_id'
                    })
                }}
            >
                Заведение
            </TableSortLabel>
        </TableCell>
        <TableCell align="right">
            <TableSortLabel
                active={!!order.start}
                direction={order.start}
                onClick={() => {
                    onChangeOrder({
                        order: selectOrderDirection(order.start),
                        orderBy: 'start'
                    })
                }}
            >
                Начало
            </TableSortLabel>
        </TableCell>
        <TableCell align="right">
            <TableSortLabel
                active={!!order.end}
                direction={order.end}
                onClick={() => {
                    onChangeOrder({
                        order: selectOrderDirection(order.end),
                        orderBy: 'end'
                    })
                }}
            >
                Завершение
            </TableSortLabel>
        </TableCell>

        {actions && <TableCell align="right">Действие</TableCell>}

    </TableRow>

}
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import AutocompleteMui from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import apiClient from "../../api/http-common";
import { useQuery } from "@tanstack/react-query";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { VARIABLES } from '../../utils/variables';

export const UsersAutocomplete = (props) => {
    return <Autocomplete
        {...props}
        url={'user'}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.displayName}
        paramsExtractor={(displayName) => ({ displayName })}
        resultExtractor={(result) => result?.data?.values}
        renderOption={(props, option, { selected }) => {
            return <li {...props}>
                <Box sx={{ display: 'flex', gap: '8px' }}>
                    <Avatar
                        alt={option.displayName}
                        src={VARIABLES.FILES_URL + `${option?.avatar?.file}`}
                    />
                    {option.displayName}
                </Box>
            </li>
        }}
    />
}

export const UsersAvailableAutocomplete = ({
    rest_id,
    start,
    end,
    ...props
}) => {
    return <Autocomplete
        {...props}
        url={'user/available'}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.displayName}
        paramsExtractor={(displayName) => ({ displayName, rest_id, start, end })}
        resultExtractor={(result) => result?.data?.values}
        renderOption={(props, option, { selected }) => {
            return <li {...props}>
                <Box sx={{ display: 'flex', gap: '8px' }}>
                    <Avatar
                        alt={option.displayName}
                        src={VARIABLES.FILES_URL + `${option?.avatar?.file}`}
                    />
                    {option.displayName}
                </Box>
            </li>
        }}
    />
}

export const RestaurantsAvailableAutocomplete = ({ user_id, multiple, selected, ...props }) => {
    return <Autocomplete
        {...props}
        multiple={multiple}
        selected={selected}
        url={'restaurants/available'}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        paramsExtractor={(name) => ({ name, user_id })}
        resultExtractor={(result) => result?.data?.values}
        renderOption={(props, option, { selected }) => (
            <li {...props}>
                <Box sx={{ display: 'flex', gap: '8px' }}>
                    <Avatar
                        alt={option.name}
                        src={VARIABLES.FILES_URL + `${option?.avatar?.file}`}
                    />
                    {option.name}
                </Box>
            </li>
        )}
    />
}

export const RestaurantsAutocomplete = (props) => {
    return <Autocomplete
        {...props}
        url={'restaurants'}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        paramsExtractor={(name) => ({ name })}
        resultExtractor={(result) => result?.data?.values}
        renderOption={(props, option, { selected }) => (
            <li {...props}>
                <Box sx={{ display: 'flex', gap: '8px' }}>
                    <Avatar
                        alt={option.name}
                        src={VARIABLES.FILES_URL + `${option?.avatar?.file}`}
                    />
                    {option.name}
                </Box>
            </li>
        )}
    />
}
export function Autocomplete({
    label,
    multiple,
    onChange,
    selected,
    url,
    isOptionEqualToValue,
    getOptionLabel,
    paramsExtractor = (query) => ({ query }),
    resultExtractor = (result) => result.data,
    renderOption,
    startAdornment,
}) {
    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');
    // const [selected, setSelected] = React.useState(null)

    const { isFetching: loading, data, refetch } = useQuery({
        queryKey: [url, paramsExtractor(text)],
        queryFn: async () => {
            return await apiClient.get(url, {
                params: paramsExtractor(text)
            });
        },
        enabled: false,
        refetchOnMount: false,
        staleTime: 200,//0000,
        refetchOnWindowFocus: false
    });
    const values = resultExtractor(data) || []
    useEffect(() => {
        open && refetch()
    }, [text, open])
    // console.log('selected', selected)
    return (
        <AutocompleteMui
            //   sx={{ width: 300 }}
            multiple={multiple}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            renderOption={renderOption}
            onChange={onChange}
            value={selected}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel}
            options={values}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (!multiple && selected) ? <Avatar
                            alt={selected.name}
                            sx={{ width: '30px', height: '30px' }}
                            src={VARIABLES.FILES_URL + `${selected?.avatar?.file}`}
                        /> : params.InputProps.startAdornment,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

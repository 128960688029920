import { useCallback, useState } from 'react'
import { getRanges, getStatuses } from './utils';

export const usePeriodicitieSchedule = ({
    setSchedule,
    schedule
}) => {
    const [day, setDay] = useState(5);

    const handleScheduleRange = useCallback((range) => {
        setSchedule((state) => ({ ...state, [day]: { ...state[day], range } }))
    }, [day])
    const handleScheduleChecked = useCallback((status) => {
        setSchedule((state) => ({ ...state, [day]: { ...state[day], status: status ? 'work' : 'not' } }));
    }, [day])

    const statuses = getStatuses(schedule)
    const ranges = getRanges(schedule)

    const available = statuses[day] === 'work'
    const currentRange = ranges[day]
    return {
        day,
        setDay,
        handleScheduleRange,
        handleScheduleChecked,
        statuses,
        ranges,
        available,
        currentRange
    }
}
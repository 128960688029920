import apiClient from "../http-common";
import { useQuery } from "@tanstack/react-query";
import { useSetProgressBar } from '../../utils/useProgressBar'

export const useUserView = (id) => {
    const { isFetching, data } = useQuery({
        queryKey: ["/user/view", { id }],
        queryFn: async () => {
            return await apiClient.get("/user/view", { params: { id } });
        },
        staleTime: 3000000
    });
    useSetProgressBar(isFetching)

    return {
        value: data?.data, isFetching
    }
}
import React, { useEffect, useState } from "react";
import { ContentLayout } from '../../Components/ContentLayout/ContentLayout'
import {
    useNavigate
} from "react-router-dom";
import { useCurrentUser } from '../../utils/useCurrentUser'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { RestaurantsAvailableAutocomplete, UsersAvailableAutocomplete } from '../../Components/Autocomplete/Autocomplete'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import LoadingButton from '@mui/lab/LoadingButton';
import { useWorkshiftsCreate } from '../../api/workshift/useWorkshiftsCreate'
import { useWorkshiftsList } from '../../api/workshift/useWorkshiftsQuery'
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import isBetween from 'dayjs/plugin/isBetween'
// import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { RequestErrorMessage } from "../../ui/RequestErrorMessage/RequestErrorMessage";
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { isArray } from "lodash";

dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
// dayjs.extend(isSameOrBefore)

const shouldDisableTimeStartCreator = (workshifts) => (date) =>
    workshifts.some((v) =>
        date.isBetween(dayjs.utc(v.start), dayjs.utc(v.end), 'minute', '[]'))
const shouldDisableTimeEndCreator = (workshifts, start) => (date) =>
    workshifts.filter(v => dayjs.utc(v.start).isSameOrAfter(start))
        .some((v) =>
            date.isSameOrAfter(dayjs.utc(v.start), 'minute'))
const shouldDisableDateEndCreator = (workshifts, start) => (date) =>
    workshifts.filter(v => dayjs.utc(v.start).isSameOrAfter(start))
        .some((v) =>
            date.isAfter(dayjs.utc(v.start), 'day'))




export const WorkshiftsCreatePage = ({ className, ...props }) => {

    const currentUser = useCurrentUser()
    const [user, setUser] = useState(null)
    const [users, setUsers] = useState([])
    const [interns, setInterns] = useState([])
    const [rest, setRest] = useState(null)
    const [commission, setCommission] = useState([0, 0])
    const [baseCommission, setBaseCommission] = useState([0, 0])
    const [disableValidation, setDisableValidation] = useState(false)
    const [internship, setInternship] = useState(false)
    const [noSales, setNoSales] = useState(false)
    const [internsNoSales, setInternsNoSales] = useState(false)
    const [description, setDescription] = useState('')
    const [start, setStart] = useState()//dayjs(new Date()))
    const [end, setEnd] = useState()//dayjs(new Date()))
    const navigate = useNavigate();

    const { values, isFetching } = useWorkshiftsList({ tab: 'active', page: 1, user_id: user?.id })
    // console.log('values', values)
    const workshifts = user?.id ? values : []



    const { mutate, isPending, error, reset } = useWorkshiftsCreate({
        onSuccess: (res) => {
            if (res.data.error) {
                return;
            }
            if (isArray(res.data)) {
                navigate(`/workshifts`);
            } else {
                navigate(`/workshifts/view/${res.data.id}`);
            }
        },
        onError: (error, variables) => {

        }
    })
    console.log('error', error)

    return <ContentLayout>
        <Box component={'form'} noValidate onSubmit={(e) => {
            e.preventDefault()
            const data = new FormData(e.currentTarget);
            const v = [...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {
                "user_id": user.id,
                "users": users,
                "restaurants_id": rest.id,
                "start": start.toDate().toLocaleString('sv-SE', { timeZone: 'UTC' }),
                "end": end.toDate().toLocaleString('sv-SE', { timeZone: 'UTC' }),//"2024-01-17 05:45:07",
                "create_at": (new Date).toLocaleString('sv-SE', { timeZone: 'UTC' }),
                "create_by": currentUser.id,
                "type": internship ? 1 : 0,
                "interns": interns,
                "no_sales": noSales ? 1 : 0,
                "interns_no_sales": internsNoSales ? 1 : 0,
            })
            console.log('data', [...data.entries()], v)
            if (user && rest) {
                mutate(v)
            }
        }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch
                                checked={internship}
                                onChange={() => {
                                    setInternship(!internship)
                                }
                                } />}
                            label="Смена-стажировка" />

                        <FormControlLabel control={<Switch
                            checked={noSales}
                            onChange={() => {
                                setNoSales(!noSales)
                            }}
                        // name={'no_sales'}
                        />} label={internship ? "Без продаж у наставника" : "Без продаж"} />

                        {internship && <FormControlLabel control={<Switch
                            checked={internsNoSales}
                            onChange={() => {
                                setInternsNoSales(!internsNoSales)
                            }}
                        // name={'interns_no_sales'}
                        />} label={"Без продаж у стажеров"} />}
                    </FormGroup>
                </Grid>

                <Grid item xs={12}>
                    <RestaurantsAvailableAutocomplete
                        onChange={(_, v) => {
                            setRest(v)
                            if (v) {
                                setCommission([v.commission, v.commission])
                                setBaseCommission([v.base_commission, v.base_commission])
                                if (user) {
                                    setUsers([user])
                                }
                            }
                        }}
                        user_id={!disableValidation && user?.id}
                        selected={rest}
                        label={'Заведение'}
                    />
                </Grid>

                {/* <Grid item xs={6} sx={{ display: 'flex' }}>
                    <DateCalendar
                        // shouldDisableTime={!disableValidation && shouldDisableTimeStartCreator(workshifts)}
                        label="Начало"
                        minDateTime={dayjs(new Date())}
                        loading={isFetching}
                        disabled={isFetching}
                        value={start}
                        onChange={(newValue) => setStart(newValue)}
                    />
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex' }}>

                </Grid> */}

                <Grid item xs={6} sx={{ display: 'flex' }}>
                    <DateTimePicker
                        shouldDisableTime={!disableValidation && shouldDisableTimeStartCreator(workshifts)}
                        sx={{ flex: '1' }}
                        label="Начало"
                        loading={isFetching}
                        disabled={isFetching}
                        minDateTime={!disableValidation ? dayjs(new Date()) : undefined}
                        // maxDateTime={end}
                        value={start}
                        onChange={(newValue) => setStart(newValue)}
                    />
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                    <DateTimePicker
                        shouldDisableTime={!disableValidation && shouldDisableTimeEndCreator(workshifts, start)}
                        shouldDisableDate={!disableValidation && shouldDisableDateEndCreator(workshifts, start)}
                        loading={isFetching}
                        disabled={isFetching}
                        sx={{ flex: '1' }}
                        label="Завершение"
                        minDateTime={start || dayjs(new Date())}
                        value={end}
                        onChange={(newValue) => setEnd(newValue)}
                    />
                </Grid>
                <Grid item xs={12}>
                    {(!rest?.id || internship) ?
                        <UsersAvailableAutocomplete
                            onChange={(_, v) => setUser(v)}
                            rest_id={!disableValidation && rest?.id}
                            start={!disableValidation && start?.toDate().toLocaleString('sv-SE', { timeZone: 'UTC' })}
                            end={!disableValidation && end?.toDate().toLocaleString('sv-SE', { timeZone: 'UTC' })}
                            selected={user}
                            label={internship ? 'Наставник' : 'Сотрудник'}
                        /> :
                        <UsersAvailableAutocomplete
                            multiple
                            onChange={(_, v) => {
                                setUsers(v)
                                setUser(v[0] || null)
                            }}
                            rest_id={!disableValidation && rest?.id}
                            start={!disableValidation && start?.toDate().toLocaleString('sv-SE', { timeZone: 'UTC' })}
                            end={!disableValidation && end?.toDate().toLocaleString('sv-SE', { timeZone: 'UTC' })}
                            selected={users}
                            label={'Сотрудники'}
                        />}
                </Grid>
                {internship && <Grid item xs={12}>
                    <UsersAvailableAutocomplete
                        multiple
                        onChange={(_, v) => setInterns(v)}
                        // rest_id={!disableValidation && rest?.id}
                        // start={!disableValidation && start?.toDate().toLocaleString('sv-SE', { timeZone: 'UTC' })}
                        // end={!disableValidation && end?.toDate().toLocaleString('sv-SE', { timeZone: 'UTC' })}
                        selected={interns}
                        label={'Стажеры'}
                    />
                </Grid>}

                {!noSales && <>
                    <Grid item xs={6}>
                        <TextField
                            label={internship ? "Комиссия(%) для наставника" : "Комиссия(%)"}
                            name="commission"
                            type="number"
                            helperText={!!(rest) && `Комиссия по умолчанию для выбранного заведения: ${rest.commission}%`}
                            fullWidth
                            value={commission[0]}
                            defaultValue={commission[0]}
                            onChange={(e) => setCommission([e.target.value, commission[1]])}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={internship ? "Базовая коммиссия для наставника" : "Базовая коммиссия"}
                            name="base_commission"
                            type="number"
                            helperText={!!(rest) && `Минимальная комиссия по умолчанию для выбранного заведения: ${rest.base_commission}`}
                            fullWidth
                            value={baseCommission[0]}
                            defaultValue={baseCommission[0]}
                            onChange={(e) => setBaseCommission([e.target.value, baseCommission[1]])}
                        />
                    </Grid>
                </>}

                {internship &&
                    !internsNoSales &&
                    <>
                        <Grid item xs={6}>
                            <TextField
                                label="Комиссия(%) для стажеров"
                                name="interns_commission"
                                type="number"
                                helperText={!!(rest) && `Комиссия по умолчанию для выбранного заведения: ${rest.commission}%`}
                                fullWidth
                                value={commission[1]}
                                defaultValue={commission[1]}
                                onChange={(e) => setCommission([commission[0], e.target.value])}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Базовая коммиссия для стажеров"
                                name="interns_base_commission"
                                type="number"
                                helperText={!!(rest) && `Минимальная комиссия по умолчанию для выбранного заведения: ${rest.base_commission}`}
                                fullWidth
                                value={baseCommission[1]}
                                defaultValue={baseCommission[1]}
                                onChange={(e) => setBaseCommission([baseCommission[0], e.target.value])}
                            />
                        </Grid>
                    </>}
                <Grid item xs={12}>
                    <TextField
                        label="Описание"
                        name="description"
                        multiline
                        fullWidth
                        rows={4}
                        defaultValue={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Grid>
                {error && <Grid item xs={12}>
                    <RequestErrorMessage
                        error={error}
                        reset={reset}
                    />
                </Grid>}
                <Grid item xs={4}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch
                                name={'disable_validation'}
                                checked={disableValidation}
                                onChange={() => setDisableValidation(!disableValidation)} />}
                            label="Disable validation" />
                    </FormGroup>
                </Grid>
                {/* <Grid item xs={6}></Grid> */}
                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton
                        loading={isPending}
                        type="submit"
                        variant="contained"
                        disabled={disableValidation ? false : ((!user && !users.length) || !rest || !start || !end || !!error)}
                    >Создать</LoadingButton>
                </Grid>
            </Grid>
        </Box>

    </ContentLayout>
}
import React, { } from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { formateDateTime } from '../../utils/utils';
import Divider from '@mui/material/Divider';
import { Link } from "react-router-dom";
import MuiLink from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress';
import { VARIABLES } from '../../utils/variables';

export const Comment = ({
    value,
    onClickApprove,
    onClickDelete,
    isPendingApprove,
    isPendingDelete
}) => {
    const { comment, user, create_at, approved } = value
    return <>
        <ListItem
            alignItems="flex-start"
            secondaryAction={
                onClickDelete && <IconButton onClick={onClickDelete} edge="end" aria-label="delete">
                    {isPendingDelete ? <CircularProgress size={24} /> : <DeleteIcon />}
                </IconButton>
            }
        >
            {onClickApprove && <ListItemIcon>
                <IconButton edge="end" disabled={isPendingApprove} onClick={onClickApprove} aria-label="approve">
                    {isPendingApprove ?
                        <CircularProgress size={24} /> :
                        <>{approved ? <VisibilityIcon /> : <VisibilityOffIcon />}</>}
                </IconButton>
            </ListItemIcon>}
            <ListItemAvatar>
                <MuiLink component={Link} to={`/users/view/${user.id}`}>
                    <Avatar alt={user.displayName} src={VARIABLES.FILES_URL + `${user.avatar?.file}`} />
                </MuiLink>
            </ListItemAvatar>
            <ListItemText
                primary={<MuiLink component={Link} to={`/users/view/${user.id}`}>{user.displayName}</MuiLink>}
                secondary={
                    <React.Fragment>
                        <Typography
                            variant="body2"
                            color="text.primary"
                        >
                            {comment}
                        </Typography>
                        <Typography
                            variant="body2"
                            fontSize={12}
                        >
                            {formateDateTime(create_at)}
                        </Typography>
                    </React.Fragment>
                }
            />
        </ListItem>
        <Divider />
    </>

}
import React, { useEffect, useState } from "react";
import { ContentLayout } from '../../Components/ContentLayout/ContentLayout'
import { ChangePasswordDialog } from '../../Components/ChangePasswordDialog/ChangePasswordDialog'
import {
    useNavigate,
    useParams
} from "react-router-dom";
// import { useUserView } from '../UsersView/UsersView'
import { useUserView } from '../../api/user/useUserView'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ImageUploader } from '../../Components/ImageUploader/ImageUploader'
import { useModal } from '../../utils/useModal'
import LoadingButton from '@mui/lab/LoadingButton';
import { useUpdateUser } from '../../api/user/useUpdateUser'
import { Preloader } from "../../ui/Preloader/Preloader";
import { CitySelect } from "../../Components/CitySelect/CitySelect";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { RequestErrorMessage } from "../../ui/RequestErrorMessage/RequestErrorMessage";
import { YandexMap } from "../../Components/YandexMap/YandexMap";
import { VARIABLES } from '../../utils/variables';
import { useRef } from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formateDateToServer } from "../../utils/utils";
import dayjs from "dayjs";

export const UsersEditPage = ({ className, ...props }) => {

    const { id } = useParams()
    const [fileResult, setFileResult] = useState(null)
    const [birthdate, setBirthdate] = useState()
    const { value, isFetching } = useUserView(id)

    const [place, setPlace] = useState([-16.460174, -133.395840])
    const ymap = useRef(null)
    useEffect(() => {
        setPlace([value?.longitude, value?.latitude])
    }, [value?.longitude, value?.latitude])


    const { handleOpen } = useModal({
        Component: ChangePasswordDialog,
        props: {
            id
        }
    })

    const navigate = useNavigate();
    const { mutate, isPending, error, reset } = useUpdateUser(id, {
        onSuccess: () => {
            navigate(`/users/view/${id}`);
        }
    })

    if (isFetching) return <ContentLayout
        title={<Preloader />}
    />
    if (!value && !isFetching) return <ContentLayout
        title={`404`}
    />

    return <ContentLayout
        title={`Редактирование "${value?.username}"`}
        action={{
            to: `/users/view/${value?.id}`,
            title: 'Просмотр'
        }}
    >

        <Box component={'form'} noValidate onSubmit={(e) => {
            e.preventDefault()

            const data = new FormData(e.currentTarget);
            const v = [...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {
                avatar: fileResult?.file_id ? fileResult?.file_id : value.avatar?.id,
                birth_date: formateDateToServer(birthdate) || value?.birth_date
            })
            console.log('data', [...data.entries()], v)

            mutate(v)
        }}>
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <ImageUploader
                        variant={'rounded'}
                        imgSx={{ width: 250, height: 250 }}
                        alt={value?.username}
                        src={VARIABLES.FILES_URL + `${fileResult?.url || value?.avatar?.file}`}
                        onUpload={(data) => {
                            setFileResult(data)
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Email"
                        name="email"
                        id="email"
                        fullWidth
                        defaultValue={value?.email}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Телефон"
                        name="phone"
                        id="phone"
                        fullWidth
                        defaultValue={value?.phone}
                    />
                </Grid>


                <Grid item xs={12}>
                    <TextField
                        label="Фамилия"
                        name="last_name"
                        id="last_name"
                        fullWidth
                        defaultValue={value?.last_name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Имя"
                        name="first_name"
                        id="first_name"
                        fullWidth
                        defaultValue={value?.first_name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Отчество"
                        name="middle_name"
                        id="middle_name"
                        fullWidth
                        defaultValue={value?.middle_name}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        type="number"
                        label="Рост"
                        name="height"
                        id="height"
                        fullWidth
                        defaultValue={value?.height}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <DatePicker
                            label="Дата рождения"
                            value={birthdate || dayjs.utc(value?.birth_date).local()}
                            onChange={(newValue) => setBirthdate(newValue)}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="username"
                        name="username"
                        id="username"
                        fullWidth
                        defaultValue={value?.username}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="restSelectCity-select-small-label">Город</InputLabel>
                        <CitySelect
                            // anyVariant
                            label="Город"
                            name="city_id"
                            labelId="restSelectCity-select-small-label"
                            id="restSelectCity-select-small"
                            onChange={(v) => {
                                const pos = [v?.longitude, v?.latitude]
                                ymap.current?.setCenter(pos)
                                setPlace(pos)
                            }}
                            value={value?.city?.id}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <YandexMap
                        height={'40vh'}
                        latitudeName={'latitude'}
                        longitudeName={'longitude'}
                        key={`edit_u_${value?.id}`}
                        onChange={setPlace}
                        onLoad={(m) => ymap.current = m}
                        place={place}
                    />
                </Grid>

                {error && <Grid item xs={12}>
                    <RequestErrorMessage
                        error={error}
                        reset={reset}
                    />
                </Grid>}


                <Grid item xs={4}>
                    <Button onClick={handleOpen} variant="contained" >Изменить пароль</Button>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton loading={isPending} type="submit" variant="contained" >Сохранить</LoadingButton>
                </Grid>
            </Grid>
        </Box>

    </ContentLayout>
}
import React, { useEffect, useMemo, useState } from "react";
import { ContentLayout } from '../../Components/ContentLayout/ContentLayout'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
    useNavigate,
    Link,
    useParams
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useWorkshiftsView } from '../../api/workshift/useWorkshiftsView'
import { PRODUCTS, VARIABLES } from "../../utils/variables";
import { useListWorkshiftResult } from "../../api/workshift-results/useListWorkshiftResult";
import MuiLink from '@mui/material/Link'
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import STATUSES from "../../utils/STATUSES";
import { Preloader } from "../../ui/Preloader/Preloader";
import List from '@mui/material/List';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useChangeResultStatus } from "../../api/workshift-results/useChangeResultStatus";
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useDeleteResult } from "../../api/workshift-results/useDeleteResult";
import { useModal } from "../../utils/useModal";
import { FileUploader } from "../../Components/FileUploader/FileUploader";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useWorkshiftsResultUpdate } from "../../api/workshift-results/useWorkshiftsResultUpdate";
import { ItemsList } from "../../Components/ItemsList/ItemsList";
import { FileItem } from "../../ui/FileItem/FileItem";
import { useFilesByWorkshift } from "../../api/workshift-files/useFilesByWorkshift";
import { useCreateWorkshiftResult } from "../../api/workshift-results/useCreateWorkshiftResult";
import { useAddWorkshiftFile } from "../../api/workshift-files/useAddWorkshiftFile";
import { useWorkshiftsResolve } from "../../api/workshift/useWorkshiftsResolve";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TableLayout } from "../../Components/Table/TableLayout";
import { WorkshiftTableHead, WorkshiftTableRow } from "../../Components/Table/WorkshiftTable";
import SchoolIcon from '@mui/icons-material/School';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { formateDateRU } from "../../utils/utils";
import Rating from '@mui/material/Rating';
import { useSetTitle } from "../../utils/useTitle";
import { useSetSpeedDial } from "../../utils/useSpeedDial";
import { ResultsTable } from "./ResultsTable";
import EditNoteIcon from '@mui/icons-material/EditNote';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import { useWorkshiftsAbsenteeism } from "../../api/workshift/useWorkshiftsAbsenteeism";
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const ResultForm = ({ discounted_amount, volume, product, file, amount, comment }) => {
    const [isEmpty, setIsEmpty] = useState(false);

    return <Grid container spacing={3} sx={{ my: 1 }}>
        <Grid item xs={12}>


            <VisuallyHiddenInput name="is_empty" value={isEmpty ? 1 : undefined} onChange={() => { }} />

            <FormControlLabel control={<Switch
                checked={isEmpty}
                onChange={(e) => setIsEmpty(e.target.checked)}
                name='is_empty'
            />} label={"Продаж не было"} />
        </Grid>

        {!isEmpty && <>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="product-select-label">Напиток</InputLabel>
                    <Select
                        // error={empty['product']}
                        labelId="product-select-label"
                        id="product"
                        label="Напиток"
                        name={'product'}
                        defaultValue={product}
                    >
                        {Object.entries(PRODUCTS).map(([v, c]) => <MenuItem key={v} value={v}>{c}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    // error={empty['volume']}
                    type='number'
                    label="Объем"
                    name="volume"
                    id="volume"
                    fullWidth
                    defaultValue={volume}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    // error={empty['amount']}
                    type='number'
                    label="Сумма за напиток"
                    name="amount"
                    id="amount"
                    fullWidth
                    defaultValue={amount}
                />
            </Grid>
        </>}
        <Grid item xs={12}>
            <TextField
                multiline
                label="Комментарий(необязательно)"
                name="comment"
                id="comment"
                fullWidth
                defaultValue={comment}
            />
        </Grid>

    </Grid>
}

const DialogBody = ({
    boxProps,
    handleClose,
    isPending,
    children,
    title
}) => {
    return <Box
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        {...boxProps}
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>

            {children}

        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Отмена</Button>
            <LoadingButton
                loading={isPending}
                variant="contained" type='submit' >
                Сохранить
            </LoadingButton>
        </DialogActions>
    </Box>
}


const CreateResultDialog = ({ handleClose, rest_id, ws_id, user_id, ...props }) => {
    const { mutateAsync, isPending } = useCreateWorkshiftResult(rest_id, ws_id, user_id)
    return <DialogBody
        isPending={isPending}
        title={'Создать результат'}
        handleClose={handleClose}
        boxProps={{
            component: 'form',
            onSubmit: async (e) => {
                e.preventDefault()
                const data = new FormData(e.currentTarget);
                const v = {
                    ...[...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {}),
                }
                try {
                    const r = await mutateAsync(v)
                    handleClose()
                } catch (error) {
                    console.log('error change password', error)
                }
            }
        }}
    >
        <ResultForm />
    </DialogBody >
}



const CreateResultButton = ({ rest_id, ws_id, user_id, ...sdProps }) => {
    const { handleOpen } = useModal({
        Component: CreateResultDialog,
        props: {
            rest_id, ws_id, user_id
        }
    }, [rest_id, ws_id])
    return <SpeedDialAction {...sdProps} onClick={handleOpen} />
}

const ResolveButton = ({ ws_id, ...sdProps }) => {
    const { mutate, isPending, error, reset } = useWorkshiftsResolve()
    return <SpeedDialAction
        {...sdProps}
        onClick={() => window.confirm('Смена будет успешно завершена') && mutate(ws_id)}
    />
}

const AbsenteeismButton = ({ ws_id, ...sdProps }) => {
    const { mutate, isPending, error, reset } = useWorkshiftsAbsenteeism()
    return <SpeedDialAction
        {...sdProps}
        onClick={() => window.confirm('Смена будет засчитана прогуленой') && mutate(ws_id)}
    />
}

const UserView = ({ user }) => {
    return <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
            <Avatar
                alt={user.displayName}
                sx={{ width: '100px', height: '100px' }}
                variant="rounded"
                src={VARIABLES.FILES_URL + `${user.avatar?.file}`}
            />
        </Grid>
        <Grid item xs={12} sm={10}>
            <Typography variant="h5" gutterBottom>
                <MuiLink component={Link} to={`/users/view/${user.id}`}>{user.displayName}</MuiLink>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {user.phone}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {user.email}
            </Typography>
        </Grid>
    </Grid>
}

const RestView = ({ restaurant }) => {
    return <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
            <Avatar
                alt={restaurant.name}
                sx={{ width: '100px', height: '100px' }}
                variant="rounded"
                src={VARIABLES.FILES_URL + `${restaurant.avatar?.file}`}
            />
        </Grid>
        <Grid item xs={12} sm={10}>
            <Stack spacing={1}>
                <Typography variant="h5" gutterBottom>
                    <MuiLink component={Link} to={`/restaurants/view/${restaurant.id}`}>{restaurant.name}</MuiLink>
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {restaurant.address}
                </Typography>
                <Stack direction={'row'}>
                    <Typography component="span" variant="body1" color="text.secondary">
                        Комиссия:&nbsp;
                    </Typography>
                    <Typography component="span" variant="body1" color="text.primary">
                        {restaurant?.commission}%
                    </Typography>
                </Stack>

                <Stack direction={'row'}>
                    <Typography component="span" variant="body1" color="text.secondary">
                        Минимальная комиссия:&nbsp;
                    </Typography>
                    <Typography component="span" variant="body1" color="text.primary">
                        {restaurant?.base_commission}
                    </Typography>
                </Stack>
            </Stack>
        </Grid>
    </Grid>
}



export const WorkshiftsViewContent = ({ className, value, id }) => {
    const [tab, setTab] = useState('results');

    const dateddddHmm = dayjs.utc(value?.start).local().locale('ru').format('dddd H:mm')



    const sdActions = useMemo(() => {
        return [
            {
                LinkComponent: Link,
                to: `/workshifts/update/${value.id}`,
                icon: <EditIcon />, name: 'w-w/edit',
                tooltipTitle: 'Редактировать смену', key: 'w-w/edit'
            },
            !value.no_sales && {
                Component: CreateResultButton,
                rest_id: value.restaurant.id, user_id: value.user.id, ws_id: id,
                icon: <EditNoteIcon />, name: 'w-w/add-result',
                tooltipTitle: 'Создать результат', key: 'w-w/add-result'
            },
            {
                Component: ResolveButton,
                ws_id: id,
                icon: <DoneOutlineIcon />, name: 'w-w/complete-ws',
                tooltipTitle: 'Завершить смену. Результаты будут приняты даже если их нет', key: 'w-w/complete-ws'
            },
            {
                Component: AbsenteeismButton,
                ws_id: id,
                icon: <AccessibleForwardIcon />, name: 'w-w/absenteeism-ws',
                tooltipTitle: 'Прогул смены', key: 'w-w/absenteeism-ws'
            },
        ].filter(Boolean)
    }, [])
    const title = 'Смена - ' + dateddddHmm
    useSetTitle(title)
    useSetSpeedDial({ key: 'workshifts/view', values: sdActions })

    const tabs = [
        (!value.no_sales) && { value: 'results', label: 'Результаты' },
        !!value.crossWorkshifts?.length && { value: 'cross', label: 'Параллельные смены' },
        value.type === 2 && { value: 'grade', label: 'Оценка' },
        value.type === 1 && { value: 'internship', label: 'Стажировки' },
        value.type === 2 && { value: 'mentor', label: 'Наставник' },
    ].filter(Boolean)

    useEffect(() => {
        setTab(tabs[0]?.value)
    }, [id])

    return <ContentLayout
        title={<>
            {`${value.restaurant.name}/${dateddddHmm}`}

        </>}
        breadcrumbNameMap={{
            [`/workshifts/view/${id}`]: title
        }}
    >
        <Grid container spacing={3}>

            <Grid item xs={12} sm={5}>
                <Stack spacing={1}>
                    <Chip icon={STATUSES.ICON[value.state]} color={STATUSES.COLOR[value.state]} label={STATUSES.TEXT[value.state]} />

                    <Typography variant="h5" gutterBottom>{formateDateRU(value?.start)}</Typography>
                    <Typography variant="h5" gutterBottom>{formateDateRU(value?.end)}</Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {value?.description}
                    </Typography>

                    <Stack direction={'row'}>
                        <Typography component="span" variant="body1" color="text.secondary">
                            Комиссия:&nbsp;
                        </Typography>
                        <Typography component="span" variant="body1" color="text.primary">
                            {value.commission}%
                        </Typography>
                    </Stack>

                    <Stack direction={'row'}>
                        <Typography component="span" variant="body1" color="text.secondary">
                            Минимальная комиссия:&nbsp;
                        </Typography>
                        <Typography component="span" variant="body1" color="text.primary">
                            {value?.base_commission}
                        </Typography>
                    </Stack>

                    <Stack direction={'row'}>
                        <Typography component="span" variant="body1" color="text.secondary">
                            Время принятия:&nbsp;
                        </Typography>
                        <Typography component="span" variant="body1" color="text.primary">
                            {value.accept_time ? formateDateRU(value?.accept_time) : 'Н/Д'}
                        </Typography>
                    </Stack>

                    <Stack direction={'row'}>
                        <Typography component="span" variant="body1" color="text.secondary">
                            Время начала работы:&nbsp;
                        </Typography>
                        <Typography component="span" variant="body1" color="text.primary">
                            {value.start_work_time ? formateDateRU(value?.start_work_time) : 'Н/Д'}
                        </Typography>
                    </Stack>

                    <Stack direction={'row'}>
                        <Typography component="span" variant="body1" color="text.secondary">
                            Время окончания работы:&nbsp;
                        </Typography>
                        <Typography component="span" variant="body1" color="text.primary">
                            {value.complete_time ? formateDateRU(value?.complete_time) : 'Н/Д'}
                        </Typography>
                    </Stack>

                    <Stack spacing={1} direction={'row'}>
                        {value.type !== 0 && <Chip
                            color='info'
                            variant='outlined'
                            icon={<SchoolIcon />}
                            label="Стажировка" />}

                        {value.no_sales && <Chip
                            color='info'
                            variant='outlined'
                            icon={<MoneyOffIcon />}
                            label="Без продаж" />}

                    </Stack>
                </Stack>
            </Grid>

            <Grid item xs={12} sm={7}>
                <Stack spacing={2} divider={<Divider />}>
                    {value.restaurant && <RestView restaurant={value.restaurant} />}

                    {value.user && <UserView user={value.user} />}
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Tabs
                            variant="scrollable"
                            scrollButtons="auto"
                            value={tab}
                            onChange={(_, v) => setTab(v)}>
                            {tabs.map(item => <Tab {...item} />)}
                        </Tabs>
                    </Grid>

                    {tab === 'results' && !value?.no_sales && <>
                        <Grid item xs={12}>
                            <ResultsTable
                                ws_id={id}
                                workshiftResults={value.workshiftResults}
                                workshiftFiles={value.workshiftFiles}
                                commission={value.commission}
                                base_commission={value.base_commission}
                            />
                        </Grid>
                    </>}
                    {tab === 'internship' && value.workshifts && <Grid item xs={12}>
                        <TableLayout
                            head={<WorkshiftTableHead actions />}
                        >
                            {value.workshifts.map((intern) => <WorkshiftTableRow key={intern.id} {...intern} actions />)}
                        </TableLayout>
                    </Grid>}

                    {tab === 'cross' && value.crossWorkshifts && <Grid item xs={12}>
                        <TableLayout
                            head={<WorkshiftTableHead actions />}
                        >
                            {value.crossWorkshifts.map((cross) => <WorkshiftTableRow key={cross.id} {...cross} actions />)}
                        </TableLayout>
                    </Grid>}

                    {tab === 'mentor' && value.parent && <Grid item xs={12}>
                        <TableLayout
                            head={<WorkshiftTableHead actions />}
                        >
                            <WorkshiftTableRow {...value.parent} actions />
                        </TableLayout>
                    </Grid>}

                    {tab === 'grade' && <Grid item xs={12}>{value.internshipGrades ?
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Критерий</TableCell>
                                        <TableCell align="right">Оценка</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Внешний вид</TableCell>
                                        <TableCell align="right">
                                            <Rating readOnly value={value.internshipGrades.appearance} />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>Дисциплина</TableCell>
                                        <TableCell align="right">
                                            <Rating readOnly value={value.internshipGrades.discipline} />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>Стремление к работе и заработку</TableCell>
                                        <TableCell align="right">
                                            <Rating readOnly value={value.internshipGrades.pursuit} />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>Находчивость/смекалка</TableCell>
                                        <TableCell align="right">
                                            <Rating readOnly value={value.internshipGrades.savvy} />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>Общительность</TableCell>
                                        <TableCell align="right">
                                            <Rating readOnly value={value.internshipGrades.sociability} />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>Комментарий</TableCell>
                                        <TableCell align="right">
                                            {value.internshipGrades.comment}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer >
                        : 'Оценка отсутствует'}</Grid>}
                </Grid>
            </Grid>
        </Grid>
    </ContentLayout >
}
export const WorkshiftsViewPage = ({ className, ...props }) => {
    const { id } = useParams()

    const { data, isFetching } = useWorkshiftsView(id);
    const value = data?.data



    if (isFetching && !value) return <ContentLayout
        title={`loading...`}
    />
    if (!value && !isFetching) return <ContentLayout
        title={`not found`}
    />

    return <WorkshiftsViewContent id={id} value={value} />
}
import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from './api/http-common'
import {
  createBrowserRouter,
  RouterProvider,
  redirect
} from "react-router-dom";
import { LoginPage } from './Pages/Login/Login'
import { HomePage } from './Pages/Home/Home'
import { UsersPage } from './Pages/Users/Users'
import { UsersCreatePage } from './Pages/UsersCreate/UsersCreate'
import { UsersViewPage } from './Pages/UsersView/UsersView'
import { UsersEditPage } from './Pages/UsersEdit/UsersEdit'
import { RestaurantsPage } from './Pages/Restaurants/Restaurants'
import { RestaurantsViewPage } from './Pages/RestaurantsView/RestaurantsView'
import { RestaurantsEditPage } from './Pages/RestaurantsEdit/RestaurantsEdit'
import { WorkshiftsPage } from './Pages/Workshifts/Workshifts'
import { WorkshiftsCreatePage } from './Pages/WorkshiftsCreate/WorkshiftsCreate'
import { WorkshiftsViewPage } from './Pages/WorkshiftsView/WorkshiftsView'
import { WorkshiftsEditPage } from './Pages/WorkshiftsEdit/WorkshiftsEdit'
import { RestaurantsCreatePage } from './Pages/RestaurantsCreate/RestaurantsCreate'
import { Layout } from './Components/Layout/Layout'
import { TitleProvider } from './utils/useTitle'
import { ProgressBarProvider } from './utils/useProgressBar'
import { ModalProvider } from './utils/useModal'

import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ru';
import { YMaps } from '@pbe/react-yandex-maps';
import { VARIABLES } from './utils/variables';
import { SpeedDialProvider } from './utils/useSpeedDial';
// const queryClient = new QueryClient();

async function loginLoader() {
  const authData = JSON.parse(localStorage.getItem("authData"));
  if (authData) {
    return redirect("/");
  }
  return null;
}
function protectedLoader({ request }) {
  const authData = JSON.parse(localStorage.getItem("authData"));
  if (!authData) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  return null;
}



const router = createBrowserRouter([
  {
    path: "/",
    // element: <HomePage />,
    Component: (props) => <ModalProvider><Layout {...props} /></ModalProvider>,
    children: [
      // {
      //   index: true,
      //   Component: PublicPage,
      // },
      {
        path: "/restaurants",
        // action: loginAction,
        loader: protectedLoader,
        // element: <LoginPage />,
        Component: RestaurantsPage,
      },
      {
        path: "/restaurants/create",
        // action: loginAction,
        loader: protectedLoader,
        // element: <LoginPage />,
        Component: RestaurantsCreatePage,
      },
      {
        path: "/restaurants/view/:id",
        // action: loginAction,
        loader: protectedLoader,
        // element: <LoginPage />,
        Component: RestaurantsViewPage,
      },
      {
        path: "/restaurants/update/:id",
        // action: loginAction,
        loader: protectedLoader,
        // element: <LoginPage />,
        Component: RestaurantsEditPage,
      },
      {
        path: "/workshifts/:tab?",
        // action: loginAction,
        loader: protectedLoader,
        // element: <LoginPage />,
        Component: WorkshiftsPage,
      },
      {
        path: "/workshifts/create",
        // action: loginAction,
        loader: protectedLoader,
        // element: <LoginPage />,
        Component: WorkshiftsCreatePage,
      },
      {
        path: "/workshifts/view/:id",
        // action: loginAction,
        loader: protectedLoader,
        // element: <LoginPage />,
        Component: WorkshiftsViewPage,
      },
      {
        path: "/workshifts/update/:id",
        // action: loginAction,
        loader: protectedLoader,
        // element: <LoginPage />,
        Component: WorkshiftsEditPage,
      },
      {
        path: "/users/:tab?",
        // action: loginAction,
        loader: protectedLoader,
        // element: <LoginPage />,
        Component: UsersPage,
      },
      {
        path: "/users/create",
        // action: loginAction,
        loader: protectedLoader,
        // element: <LoginPage />,
        Component: UsersCreatePage,
      },
      {
        path: "/users/view/:id",
        // action: loginAction,
        loader: protectedLoader,
        // element: <LoginPage />,
        Component: UsersViewPage,
      },
      {
        path: "/users/update/:id",
        // action: loginAction,
        loader: protectedLoader,
        // element: <LoginPage />,
        Component: UsersEditPage,
      },
      {
        // path: "protected",
        index: true,
        loader: protectedLoader,
        Component: HomePage,
      },
    ],
  },
  {
    path: "/login",
    loader: loginLoader,
    Component: LoginPage,
    // element: <LoginPage />,
  },
], { basename: VARIABLES.BASE_URL });

window.router = router
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      {/* <App /> */}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ProgressBarProvider>
            <TitleProvider>
              <SpeedDialProvider>
                <YMaps
                  query={{
                    apikey: VARIABLES.YANDEX_MAP_APIKEY,
                    suggest_apikey: VARIABLES.YANDEX_MAP_SUGGEST_APIKEY
                  }}
                >
                  <RouterProvider router={window.router} />
                </YMaps>
              </SpeedDialProvider>
            </TitleProvider>
          </ProgressBarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useCallback, useState } from 'react'
import dayjs from 'dayjs';
import { setTimeZero } from '../../../utils/utils';
import { getRanges } from './utils';

export const useParticularsSchedule = ({
    dates,
    setDates,
    schedule
}) => {
    const [date, setDate] = useState(dayjs());

    const dateKey = date.format('YYYY-MM-DD')

    const currentDay = date.day()

    const currentSchedule = schedule[currentDay]

    const ranges = getRanges(schedule)

    const currentRange = getRanges(dates)[dateKey] || ranges[currentDay]

    const handleDatesRange = useCallback((range) => {
        setDates((state) => {
            const c = setTimeZero(date)
            return ({
                ...state, [dateKey]: {
                    ...state[dateKey],
                    range,
                    date: c,
                    day: c.day(),
                    start: c.set('second', range[0]),
                    end: c.set('second', range[1]),
                    status: state[dateKey]?.status || currentSchedule.status
                }
            })
        })
    }, [date, dateKey, currentSchedule])

    const handleDatesChecked = useCallback((status) => {
        setDates((state) => {
            const r = state[dateKey]?.range || currentSchedule.range
            const c = setTimeZero(date)
            return ({
                ...state, [dateKey]: {
                    ...state[dateKey],
                    status: status ? 'work' : 'not',
                    date: c,
                    day: c.day(),
                    start: c.set('second', r[0]),
                    end: c.set('second', r[1]),
                    range: r
                }
            })
        })
    }, [dateKey, currentSchedule])

    return {
        date,
        setDate,
        dateKey,
        currentDay,
        currentSchedule,
        ranges,
        currentRange,
        handleDatesRange,
        handleDatesChecked
    }
}
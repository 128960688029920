import apiClient, { queryClient } from "../http-common";
import { useMutation } from "@tanstack/react-query";
// import {
//     useRevalidator
// } from "react-router-dom";
// import { errorReports } from "./errorReports";


export const useCreateWorkshiftResult = (rest_id, ws_id, user_id, { onSuccess = () => { }, onError = () => { } } = {}) => {
    // const revalidator = useRevalidator();
    return useMutation({
        mutationKey: ["workshift-results/create"],
        mutationFn: async (data) => {
            return await apiClient.post("workshift-results/create", {
                ws_id,
                rest_id,
                user_id,
                ...data
            });
        },
        onSuccess: async (res) => {
            await queryClient.invalidateQueries({ queryKey: ['workshift/active'] })
            await queryClient.invalidateQueries({ queryKey: ['workshift/index'] })
            await queryClient.invalidateQueries({ queryKey: ['workshift-results/index'] })
            await queryClient.invalidateQueries({ queryKey: [`workshift/view`] })
            // revalidator.revalidate();
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
            // errorReports(err)
        }
    })
}
import {
    Form,
    Link,
    Outlet,
    RouterProvider,
    createBrowserRouter,
    redirect,
    useActionData,
    useFetcher,
    useLocation,
    useNavigation,
    useRouteLoaderData
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { isString } from "lodash";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';

const breadcrumbBaseNameMap = {
    '/workshifts': 'Смены',
    '/workshifts/create': 'Создание смены',
    '/workshifts/update': 'Редактирование смены',
    '/workshifts/view/:id': 'Просмотр смены',

    '/restaurants': 'Заведения',
    '/restaurants/create': 'Добавить заведение',
    '/restaurants/update': 'Редактирование заведения',
    '/restaurants/view/:id': 'Просмотр заведения',

    '/users': 'Сотрудники',
    '/users/create': 'Добавить сотрудника',
    '/users/update': 'Редактирование сотрудника',
    '/users/view/:id': 'Просмотр сотрудника',
};

function LinkRouter(props) {
    return <MuiLink {...props} component={Link} />;
}

export const ContentLayout = ({
    children,
    title,
    breadcrumbNameMap
}) => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    const breadcrumbFullNameMap = { ...breadcrumbBaseNameMap, ...breadcrumbNameMap }
    return <Grid container spacing={3}>
        {/* <Grid item xs={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            {pathnames.map((value, index) => {
                                const last = index === pathnames.length - 1;
                                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                                if(!breadcrumbFullNameMap[to]) return null

                                return last ? (
                                    <Typography color="text.primary" key={to}>
                                        {breadcrumbFullNameMap[to]}
                                    </Typography>
                                ) : (
                                    <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                                        {breadcrumbFullNameMap[to]}
                                    </LinkRouter>
                                );
                            })}

                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Paper>
        </Grid> */}

        <Grid item xs={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container spacing={2} sx={{ /*borderBottom: 1, borderColor: 'divider',*/ marginBottom: '12px' }}>
                    <Grid item xs={12}>
                        {isString(title) ?
                            <Typography variant="h6" component="h1" fontWeight={'bold'} gutterBottom>
                                {title}
                            </Typography> :
                            title}

                    </Grid>
                    {/* {action && <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button LinkComponent={Link} to={action.to}>
                            {action.title}
                        </Button>
                    </Grid>} */}
                </Grid>
                {children}
            </Paper>
        </Grid>
    </Grid>


}
import {
    Form,
    // Link,
    Outlet,
    RouterProvider,
    createBrowserRouter,
    redirect,
    useActionData,
    useFetcher,
    useLocation,
    useNavigate,
    useNavigation,
    useRouteLoaderData
} from "react-router-dom";
import MuiAppBar from '@mui/material/AppBar';
import logo from './logo-1.png.webp'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useGetTitle } from '../../utils/useTitle'
import LinearProgress from '@mui/material/LinearProgress';
import { useGetProgressBar } from '../../utils/useProgressBar'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useCitiesList } from "../../api/cities/useCitiesList";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useJWT } from "../../utils/useCurrentUser";
import { CitySelect } from "../CitySelect/CitySelect";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const drawerWidth = 240;

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));



const GlobalSelectCity = () => {
    const jwt = useJWT()
    const [city, setCity] = useLocalStorage('selected_city', jwt.city_id)

    const handleChange = (value) => {
        setCity(value.id);
    };

    return <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="GlobalSelectCity-select-small-label">Город</InputLabel>
        <CitySelect
            anyVariant
            labelId="GlobalSelectCity-select-small-label"
            id="GlobalSelectCity-select-small"
            value={city}
            label="Город"
            onChange={handleChange}
        />
    </FormControl>
}

export const Header = ({ open, toggleDrawer }) => {
    const title = useGetTitle()
    const progress = useGetProgressBar()
    const navigate = useNavigate();

    return <AppBar position="absolute" open={open}>
        {progress && <LinearProgress color="secondary" sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />}
        <Toolbar
            sx={{
                pr: '24px', // keep right padding when drawer closed
            }}
        >
            <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                    marginRight: '36px',
                    ...(open && { display: 'none' }),
                }}
            >
                <MenuIcon />
            </IconButton>

            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="back"
                sx={{ mr: 2 }}
                onClick={() => {
                    navigate(-1)
                }}
            // LinkComponent={Link}
            // to={backTo}
            >
                <ArrowBackIcon />
            </IconButton>

            <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
            >
                {title}
            </Typography>

            <GlobalSelectCity />



            {/* <IconButton color="inherit">
                <Badge badgeContent={4} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton> */}
        </Toolbar>
    </AppBar>
}
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { useChangePassword } from '../../api/user/useChangePassword'

export const ChangePasswordDialog = ({ className, handleClose, id, ...props }) => {
    const [notEqual, setNotEqual] = useState(false)

    const { mutateAsync, isPending } = useChangePassword(id)

    return <Box
            component={'form'}
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            onSubmit={async (e) => {
                e.preventDefault()
                setNotEqual(false)
                const data = new FormData(e.currentTarget);
                const v = {
                    ...[...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {}),
                }
                console.log('data', [...data.entries()], v)
                if (v.password === v.confirm_password) {
                    try {
                        const r = await mutateAsync(v)
                        handleClose()
                    } catch (error) {
                        console.log('error change password', error)
                    }

                } else {
                    setNotEqual(true)
                }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                CИзменить пароль
            </DialogTitle>
            <DialogContent>

                <Grid container spacing={3} sx={{my: 1}}>

                    <Grid item xs={12}>
                        <TextField
                            label="Пароль"
                            name="password"
                            id="password"
                            type="password"
                            fullWidth
                            autoFocus
                            error={notEqual}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Ещё раз пароль"
                            name="confirm_password"
                            id="confirm_password"
                            type="password"
                            fullWidth
                            error={notEqual}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Отмена</Button>
                <LoadingButton loading={isPending} variant="contained" type='submit' >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Box>
}
import apiClient from "../http-common";
import { useQuery } from "@tanstack/react-query";
import { useSetProgressBar } from '../../utils/useProgressBar'
import {
    useLocation,
    useParams,
    useSearchParams
} from "react-router-dom";

export const useRestaurantsQuery = () => {
    const [query] = useSearchParams();
    const page = query.get("page") || 1
    const { isFetching, data } = useQuery({
        queryKey: ["restaurants", { page }],
        queryFn: async () => {
            return await apiClient.get("restaurants", {
                params: {
                    page
                }
            });
        },
        staleTime: 1500000
    }
    );
    useSetProgressBar(isFetching)

    const { values = [], ...meta } = data?.data || {}
    return { values, meta, currentPage: page, isLoading: isFetching }
}
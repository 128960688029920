import apiClient from "../http-common";
import { useQuery } from "@tanstack/react-query";
import {
    useLocation,
    useParams,
    useSearchParams
} from "react-router-dom";

export const useFilesByRest = (rest_id, category, page = 0) => {
    const { data, isFetching } = useQuery(
        {
            queryKey: ['rest-files/index', { rest_id, category, page }],
            queryFn: async () => {
                return await apiClient.get(`rest-files/index`, {
                    params: {
                        rest_id,
                        page,
                        category
                        // page: query.get("page")
                    }
                });

            },
            staleTime: 3000000
        }
    );
    const { values = [], ...meta } = data?.data || {}
    return { values, meta, isFetching }
}
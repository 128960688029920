import { useMutation } from "@tanstack/react-query";
import { useLocalStorage } from "@uidotdev/usehooks";
import apiClient from "../http-common";
import {
    useNavigate
} from "react-router-dom";

export const useSingin = ({
    to = '/',
    onSuccess = () => { },
    onError = () => { }
} = {}) => {
    const navigate = useNavigate();
    const [authData, setAuthData] = useLocalStorage('authData', null)
    return useMutation({
        mutationKey: "auth/login",
        mutationFn: async ({
            username,
            password,
            rememberMe
        }) => {
            return await apiClient.post("auth/login", {
                "LoginForm": {
                    username,
                    password,
                    "rememberMe": rememberMe ? "1" : "0"
                }
            });
        },
        onSuccess: (res) => {
            onSuccess(res)
            if (res.data.error) {
                console.log(res.data)
                return;
            }
            setAuthData(res.data)
            navigate(to);
        },
        onError: (err) => {
            onError(err)
            console.log('auth/login err', err)
        },
    })
}
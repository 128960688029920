import apiClient, { queryClient } from "../http-common";
import { useMutation } from "@tanstack/react-query";
import {
    useRevalidator
} from "react-router-dom";


export const useDeleteWorkshiftFile = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    const revalidator = useRevalidator();
    return useMutation({
        mutationKey: ["workshift-files/delete"],
        mutationFn: async (id) => {
            return await apiClient.delete("workshift-files/delete", { params: { id } });
        },
        onSuccess: async (res) => {
            await queryClient.invalidateQueries({ queryKey: ['workshift/active'] })
            await queryClient.invalidateQueries({ queryKey: ['workshift/index'] })
            await queryClient.invalidateQueries({ queryKey: ['workshift-files/index'] })
            await queryClient.invalidateQueries({ queryKey: [`workshift/view`] })
            revalidator.revalidate();
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
        }
    })
}
import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';
import { Pagination } from '../Pagination/Pagination'
import { isNumber } from "lodash";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { Preloader } from "../../ui/Preloader/Preloader";

export const TableLayout = ({
    pageCount,
    currentPage,
    handleChangePage,
    children,
    rowCount,
    colCount,
    isLoading,
    head
}) => {
    const isEmpty = isNumber(rowCount) ? rowCount === 0 : false
    const palceholderColSpan = isNumber(colCount) ? colCount : 1
    return <Box>
        <Box sx={{ overflowX: 'auto' }}>
            <Table size="small">
                <TableHead>
                    {head}
                </TableHead>

                <TableBody>
                    {isEmpty ? <TableRow>
                        <TableCell align="center" colSpan={palceholderColSpan}>
                            {/* <Paper>
                                <Container maxWidth={false} sx={{ mt: '24px' }}> */}
                                    {isLoading ? <Preloader /> : 'Нет данных'}
                                {/* </Container>
                            </Paper> */}
                        </TableCell>
                    </TableRow> : children}
                </TableBody>
            </Table>
        </Box>
        {pageCount > 1 && <Box sx={{ marginTop: '8px', display: 'flex', justifyContent: 'flex-end' }}>
            <Pagination handleChange={handleChangePage} current={currentPage} count={pageCount} />
        </Box>}
    </Box>
}
import { useMutation } from "@tanstack/react-query";
import apiClient, { queryClient } from "../http-common";


export const useDeleteResult = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: ["workshift-results/delete"],
        mutationFn: async (id) => {
            return await apiClient.delete("workshift-results/delete", { params: { id } });
        },
        onSuccess: async (res) => {
            await queryClient.invalidateQueries({ queryKey: ['workshift-results/index'] })
            await queryClient.invalidateQueries({ queryKey: ['workshift/view'] })
            await queryClient.invalidateQueries({ queryKey: ['workshift/index'] })
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
        }
    })
}
import apiClient, { queryClient } from "../http-common";
import { useMutation } from "@tanstack/react-query";



export const useWorkshiftsResultUpdate = (id, { onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: ["workshift-results/update", { id }],
        mutationFn: async (value) => {
            return await apiClient.put("workshift-results/update", value, { params: { id } });
        },
        onSuccess: (res) => {
            queryClient.invalidateQueries({ queryKey: ['workshift-results/index'] })
            queryClient.invalidateQueries({ queryKey: [`/workshift-results/view`, { id }] })
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
        }
    })
}
import React, { useEffect, useState } from "react";
import { useUsersQuery } from '../../api/user/useUsersQuery'
import { ContentLayout } from '../../Components/ContentLayout/ContentLayout'
import { useSetTitle } from '../../utils/useTitle'
import { TableLayout } from '../../Components/Table/TableLayout'
import { UserTableHead, UserTableRow, ActionToEditUser } from '../../Components/Table/UserTable'
import IconButton from '@mui/material/IconButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useActivateUser } from "../../api/user/useActivateUser";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { useDisableUser } from "../../api/user/useDisableUser";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSetSpeedDial } from "../../utils/useSpeedDial";
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useInformationFiles } from "../../api/information-files/useInformationFiles";
import { UsersFilesList } from "./UsersFilesList/UsersFilesList";
import { Preloader } from "../../ui/Preloader/Preloader";

const ActionActivateUser = ({ id }) => {
    const { mutate, isPending } = useActivateUser()
    return <IconButton disabled={isPending} onClick={() => window.confirm('Активировать сотрудника?') && mutate(id)}><PersonAddIcon /></IconButton>
}

const ActionDisableUser = ({ id }) => {
    const { mutate, isPending } = useDisableUser()
    return <IconButton disabled={isPending} onClick={() => window.confirm('Отключить сотрудника?') && mutate(id)}><PersonOffIcon /></IconButton>
}

const sdActions = {
    key: 'users/index', values: [
        { LinkComponent: Link, to: '/users/create', icon: <AddIcon />, name: 'u-i/create', tooltipTitle: 'Добавить сотрудника', key: 'u-i/create' },
    ]
}

const UsersFilesListLoader = ({ }) => {
    const { data, isError, isFetching } = useInformationFiles()
    if (isError) return <>Error</>
    if (isFetching && !data?.data) return <Preloader />
    const { values } = data?.data || {}
    return <UsersFilesList values={values} />
}

const UsersList = ({ tab }) => {
    const { values, meta, currentPage, isLoading } = useUsersQuery({ tab })


    const pageCount = Math.ceil((meta?.pagination?.totalCount || 0) / (meta?.pagination?.defaultPageSize || 1))

    return <TableLayout
        rowCount={values.length}
        colCount={6}
        isLoading={isLoading}

        pageCount={pageCount}
        currentPage={currentPage}
        head={<UserTableHead actions />}
    >
        {values.map((value) => <UserTableRow key={value.id} {...value} actions={<Stack
            divider={<Divider orientation="vertical" flexItem />}
            justifyContent="flex-start"
            direction="row"
            spacing={1}>
            {(value.status === 9 || value.status === 0) && <ActionActivateUser id={value.id} />}
            {(value.status === 9 || value.status === 10) && <ActionDisableUser id={value.id} />}
            <ActionToEditUser id={value.id} />
        </Stack>}
        />)}
    </TableLayout>
}

export const UsersPage = ({ className, ...props }) => {
    useSetTitle('Сотрудники')
    useSetSpeedDial(sdActions)

    const { tab = 'active' } = useParams()


    return <ContentLayout
        title={<Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tab} >
            <Tab LinkComponent={Link} label="Активные" value={'active'} to={'/users/active'} />
            <Tab LinkComponent={Link} label="Ожидают активацию" value={'wait'} to={'/users/wait'} />
            <Tab LinkComponent={Link} label="Отключены" value={'blocked'} to={'/users/blocked'} />
            <Tab LinkComponent={Link} label="Все" value={'all'} to={'/users/all'} />
            <Tab LinkComponent={Link} label="Правила работы" value={'files'} to={'/users/files'} />
        </Tabs>}
    >
        {tab !== 'files' && <UsersList tab={tab} />}
        {tab === 'files' && <UsersFilesListLoader />}
    </ContentLayout>
}
import React, { useState } from "react"
import { useUserBanList } from "../../api/rest-user-ban/useUserBanList"
import { Preloader } from "../../ui/Preloader/Preloader"
import { TableLayout } from "../Table/TableLayout"
import { UserTableHead, UserTableRow } from "../Table/UserTable"
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useBanDelete } from "../../api/rest-user-ban/useBanDelete";
import CircularProgress from '@mui/material/CircularProgress';
import { RestaurantTableHead, RestaurantTableRow } from "../Table/RestaurantTable"

const ActionUnbanUser = ({ id }) => {
    const [isPending, setPending] = useState(false)
    const { mutate } = useBanDelete({
        onError: (error) => {
            console.error('error', error)
            setPending(false)
        }
    })
    const onClick = () => {
        mutate(id)
        setPending(true)
    }
    return <IconButton disabled={isPending} onClick={onClick}>{isPending ? <CircularProgress size={24} /> : <DeleteIcon />}</IconButton>
}

export const BannedTable = ({ rest_id, user_id }) => {
    const [page, setPage] = useState(1)
    const { values, isFetching, meta } = useUserBanList({ rest_id, user_id, page })

    const { pagination } = meta || {}

    const pageCount = Math.ceil((pagination?.totalCount || 0) / (pagination?.defaultPageSize || 1))

    const handleChangePage = (e, value) => {
        setPage(value)
    }

    if (!values?.length && isFetching) return <Preloader />
    if (!values?.length) return <>Заблокированых нет</>

    return <TableLayout
        handleChangePage={handleChangePage}
        pageCount={pageCount}
        currentPage={page}  
        head={user_id ? <RestaurantTableHead actions /> : <UserTableHead actions />}
    >
        {values.map((value) => user_id ? <RestaurantTableRow key={value.id} {...value.rest} /> : <UserTableRow
            key={value.id}
            {...value.user}
            actions={<ActionUnbanUser id={value.ban_id} />}
        />)}
    </TableLayout>
}
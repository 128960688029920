import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MuiLink from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { VARIABLES } from '../../utils/variables';
import { formateDate } from "../../utils/utils";

export const UserTableRow = ({
    id,
    displayName,
    avatar,
    email,
    actions,
    status,
    phone,
    birth_date
}) => {
    return <TableRow>
        <TableCell>
            <MuiLink component={Link} to={`/users/view/${id}`}>
                <Avatar
                    alt={displayName}
                    src={VARIABLES.FILES_URL + `${avatar?.file}`} />
            </MuiLink>
        </TableCell>
        <TableCell><MuiLink component={Link} to={`/users/view/${id}`}>{displayName}</MuiLink></TableCell>
        <TableCell>{birth_date && formateDate(birth_date)}</TableCell>
        <TableCell>{phone}</TableCell>
        <TableCell>{status}</TableCell>
        {actions && <TableCell align="right">
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {actions}
            </Box>
        </TableCell>}
    </TableRow>
}

export const ActionToEditUser = ({ id }) => {
    return <IconButton LinkComponent={Link} to={`/users/update/${id}`}><EditIcon /></IconButton>
}

export const UserTableHead = ({ actions }) => {
    return <TableRow>
        <TableCell>Аватар</TableCell>
        <TableCell>Имя</TableCell>
        <TableCell>Дата рождения</TableCell>
        <TableCell>Телефон</TableCell>
        <TableCell>Статус</TableCell>
        {actions && <TableCell align="right">Действия</TableCell>}
    </TableRow>

}
import React, { useEffect, useState } from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close';

export const RequestErrorMessage = ({
    error,
    reset
}) => {
    return <Alert severity="error" action={<IconButton onClick={reset}><CloseIcon /></IconButton>}>
        <AlertTitle>{error.response?.status}{error.code}</AlertTitle>
        {error.response?.data?.[0]?.message || error.message}
    </Alert>
}
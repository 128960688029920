import React, { useEffect, useState } from "react";
import { ContentLayout } from '../../Components/ContentLayout/ContentLayout'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
    useNavigate,
    useParams
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import { useWorkshiftsView } from '../../api/workshift/useWorkshiftsView'
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { RestaurantsAutocomplete, UsersAutocomplete } from '../../Components/Autocomplete/Autocomplete'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import { useCurrentUser } from '../../utils/useCurrentUser'
import { useWorkshiftsUpdate } from '../../api/workshift/useWorkshiftsUpdate'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
dayjs.extend(utc);


const WorkshiftsEditForm = ({ id, value }) => {
    const currentUser = useCurrentUser()
    const [user, setUser] = useState(value?.user)
    const [rest, setRest] = useState(value?.restaurant)
    const [commission, setCommission] = useState(value?.commission)
    const [baseCommission, setBaseCommission] = useState(value?.base_commission)
    // const [disableValidation, setDisableValidation] = useState(false)

    const [noSales, setNoSales] = useState(value?.no_sales)

    // const [description, setDescription] = useState('')
    const [start, setStart] = useState(dayjs.utc(value?.start).local())//dayjs(new Date()))
    const [end, setEnd] = useState(dayjs.utc(value?.end).local())//dayjs(new Date()))
    const navigate = useNavigate();
    const { isPending, mutate } = useWorkshiftsUpdate(id, {
        onSuccess: (res) => {
            navigate(`/workshifts/view/${id}`);
        }
    })


    return <Box component={'form'} noValidate onSubmit={(e) => {
        e.preventDefault()
        const data = new FormData(e.currentTarget);
        const v = [...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {
            "user_id": user.id,
            "restaurants_id": rest.id,
            "start": start.toDate().toLocaleString('sv-SE', { timeZone: 'UTC' }),
            "end": end.toDate().toLocaleString('sv-SE', { timeZone: 'UTC' }),//"2024-01-17 05:45:07",
            "create_at": (new Date).toLocaleString('sv-SE', { timeZone: 'UTC' }),
            "create_by": currentUser.id,
            "no_sales": noSales ? 1 : 0,
        })
        console.log('data', [...data.entries()], v)
        if (user && rest) {
            mutate(v)
        }
    }}>

        <Grid container spacing={3}>

            <Grid item xs={12}>
                <FormGroup>
                    {/* <FormControlLabel
                        control={<Switch
                            checked={internship}
                            onChange={() => {
                                setInternship(!internship)
                            }} />}
                        label="Смена-стажировка" /> */}

                    <FormControlLabel control={<Switch
                        checked={noSales}
                        onChange={() => {
                            setNoSales(!noSales)
                        }}
                    // name={'no_sales'}
                    />} label={"Без продаж"} />

                    {/* {internship && <FormControlLabel control={<Switch
                        checked={internsNoSales}
                        onChange={() => {
                            setInternsNoSales(!internsNoSales)
                        }}
                    // name={'interns_no_sales'}
                    />} label={"Без продаж у стажеров"} />} */}
                </FormGroup>
            </Grid>

            {/* Chart */}
            
            <Grid item xs={12}>
                <RestaurantsAutocomplete
                    onChange={(_, v) => setRest(v)}
                    selected={rest}
                    label={'Заведение'}
                />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex' }}>
                <DateTimePicker
                    sx={{ flex: '1' }}
                    label="Начало"
                    // minDateTime={dayjs(new Date())}
                    // maxDateTime={end}
                    value={start}
                    onChange={(newValue) => setStart(newValue)}
                />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex' }}>
                <DateTimePicker
                    sx={{ flex: '1' }}
                    label="Завершение"
                    // minDateTime={start || dayjs.utc(value?.start).local()}
                    value={end}
                    onChange={(newValue) => setEnd(newValue)}
                />
            </Grid>

            <Grid item xs={12}>
                <UsersAutocomplete
                    onChange={(_, v) => setUser(v)}
                    selected={user}
                    label={'Сотрудник'}
                />
            </Grid>

            {/* {internship && <Grid item xs={12}>
                <UsersAutocomplete
                    multiple
                    onChange={(_, v) => setInterns(v)}
                    // rest_id={!disableValidation && rest?.id}
                    // start={!disableValidation && start?.toDate().toLocaleString('sv-SE', { timeZone: 'UTC' })}
                    // end={!disableValidation && end?.toDate().toLocaleString('sv-SE', { timeZone: 'UTC' })}
                    selected={interns}
                    label={'Стажеры'}
                />
            </Grid>} */}

            {!noSales && <>
                <Grid item xs={6}>
                    <TextField
                        label={"Комиссия(%)"}
                        name="commission"
                        type="number"
                        helperText={!!(rest) && `Комиссия по умолчанию для выбранного заведения: ${rest.commission}%`}
                        fullWidth
                        value={commission}
                        defaultValue={commission}
                        onChange={(e) => setCommission(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={"Базовая коммиссия"}
                        name="base_commission"
                        type="number"
                        helperText={!!(rest) && `Минимальная комиссия по умолчанию для выбранного заведения: ${rest.base_commission}`}
                        fullWidth
                        value={baseCommission}
                        defaultValue={baseCommission}
                        onChange={(e) => setBaseCommission(e.target.value)}
                    />
                </Grid>
            </>}

            {/* {internship &&
                !internsNoSales &&
                <>
                    <Grid item xs={6}>
                        <TextField
                            label="Комиссия(%) для стажеров"
                            name="interns_commission"
                            type="number"
                            helperText={!!(rest) && `Комиссия по умолчанию для выбранного заведения: ${rest.commission}%`}
                            fullWidth
                            value={commission[1]}
                            defaultValue={commission[1]}
                            onChange={(e) => setCommission([commission[0], e.target.value])}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Базовая коммиссия для стажеров"
                            name="interns_base_commission"
                            type="number"
                            helperText={!!(rest) && `Минимальная комиссия по умолчанию для выбранного заведения: ${rest.base_commission}`}
                            fullWidth
                            value={baseCommission[1]}
                            defaultValue={baseCommission[1]}
                            onChange={(e) => setBaseCommission([baseCommission[0], e.target.value])}
                        />
                    </Grid>
                </>} */}

            {/* <Grid item xs={12}>
                <TextField
                    label="Комиссия(%)"
                    name="commission"
                    type="number"
                    helperText={!!(rest || value?.restaurant) && `Комиссия в выбраном заведение: ${(rest || value?.restaurant).commission}%`}
                    fullWidth
                    defaultValue={(rest || value?.restaurant).commission}
                // onChange={(e) => setDescription(e.target.value)}
                />
            </Grid> */}
            <Grid item xs={12}>
                <TextField
                    label="Описание"
                    name="description"
                    multiline
                    fullWidth
                    rows={4}
                    defaultValue={value?.description}
                // onChange={(e) => setDescription(e.target.value)}
                />
            </Grid>
            {/* <Grid item xs={}></Grid> */}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                    loading={isPending}
                    type="submit" variant="contained" disabled={!user || !rest} >Сохранить</LoadingButton>
            </Grid>
        </Grid>
    </Box>
}

export const WorkshiftsEditPage = ({ className, ...props }) => {
    const { id } = useParams()


    const { data, isFetching } = useWorkshiftsView(id);
    const value = data?.data

    // useEffect(() => {
    //     if (value) {
    //         setUser(value.user)
    //         setRest(value.restaurant)
    //         setStart(dayjs.utc(value?.start).local())
    //         setEnd(dayjs.utc(value?.end).local())
    //     }
    // }, [value])

    if (isFetching) return <ContentLayout
        title={`loading...`}
    />
    if (!value && !isFetching) return <ContentLayout
        title={`not found`}
    />

    return <ContentLayout
        title={`Редактирование "${value?.restaurant.name}/${dayjs.utc(value?.start).local().locale('ru').format('dddd H:mm')}"`}
        action={{
            to: `/workshifts/view/${value?.id}`,
            title: 'Просмотр'
        }}
    >
        <WorkshiftsEditForm id={id} value={value} />
    </ContentLayout>
}
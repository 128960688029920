import apiClient, { queryClient } from "../http-common";
import { useMutation } from "@tanstack/react-query";



export const useWorkshiftsResolve = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: ["workshift/resolve"],
        mutationFn: async (id) => {
            return await apiClient.put("workshift/resolve", { id });
        },
        onSuccess: async (res) => {
            await queryClient.invalidateQueries({ queryKey: ['workshift'] })
            await queryClient.invalidateQueries({ queryKey: [`workshift/view`] })
            await queryClient.invalidateQueries({ queryKey: ['workshift-results/index'] })
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
        }
    })
}
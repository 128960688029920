import { useMutation } from "@tanstack/react-query";
import apiClient, { queryClient } from "../http-common";

export const useAddUserPersonalFile = (user_id, { onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: ["users-personal-files/create", { user_id }],
        mutationFn: async ({
            file_id,
            category
        }) => {
            return await apiClient.post("users-personal-files/create", {
                user_id,
                file_id,
                category
            });
        },
        onSuccess: (res) => {
            queryClient.invalidateQueries({ queryKey: ['users-personal-files/index'] })
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
            console.log('rest-comments/create err', err)
        },
    })
}
import apiClient from "../http-common";
// import { useQuery } from "react-query";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import {
    useLocation
} from "react-router-dom";
import { useSetProgressBar } from '../../utils/useProgressBar'
function useQueryParams() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const useUsersQuery = (p = {tab: 'index'}) => {
    const { tab, ...params } = p
    const query = useQueryParams()
    const page = query.get("page") || 1
    const { isFetching, data} = useQuery({
        queryKey: ["user", { tab, page }],
        queryFn: async () => {
            return await apiClient.get(`user/${tab}`, {
                params: {
                    page
                }
            });
        },
        refetchOnWindowFocus: false,
        staleTime: 200000
    }

    );
    useSetProgressBar(isFetching)

    const { values = [], ...meta } = data?.data || {}
    return { values, meta, currentPage: page, isLoading: isFetching }
}
import { Link } from "react-router-dom";
import React, { } from "react";
import PaginationMui from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';


export const Pagination = ({
    count = 0,
    current = 1,
    handleChange
}) => {

    return <PaginationMui
        count={count}
        page={+current}
        defaultPage={+current}
        boundaryCount={2}
        onChange={handleChange}
        renderItem={(item) => (
            handleChange ? <PaginationItem {...item} /> : <PaginationItem
                component={Link}
                to={`?page=${item.page}`}
                {...item}
            />
        )}
    />
}
import {
    Outlet
} from "react-router-dom";

import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

import { AppBar, Header } from '../Header/Header'
import { Drawer, Menu } from '../Menu/Menu'
import { useLocalStorage } from "@uidotdev/usehooks";

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { useGetSpeedDialActions } from "../../utils/useSpeedDial";

const actions = [
    { icon: <FileCopyIcon />, name: 'Copy' },
    { icon: <SaveIcon />, name: 'Save' },
    { icon: <PrintIcon />, name: 'Print' },
    { icon: <ShareIcon />, name: 'Share' },
];

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="#">
                Tequila Team Russia
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;
// 



export function Layout() {
    const [open, setOpen] = useLocalStorage('main_menu_open', false);
    const sdActions = useGetSpeedDialActions()

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Box sx={{ display: 'flex' }}>

            <Header open={open} toggleDrawer={toggleDrawer} />

            <Menu open={open} toggleDrawer={toggleDrawer} />

            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
                <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>

                    <Outlet />

                    <Copyright sx={{ pt: 4 }} />
                </Container>
            </Box>
            <SpeedDial
                hidden={!sdActions.length}
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
            >
                {sdActions.map(({ Component = SpeedDialAction, ...action }) => (
                    <Component
                        {...action}

                    />
                ))}
            </SpeedDial>
        </Box>
    );
}




export const FILES_URL = window.FILES_URL || ''
export const BASE_URL = window.BASE_URL || ''
export const API_BASE_URL = window.API_BASE_URL || "http://localhost/yii/yii-application/backend/web/index.php?r="

export const HEADER_ORIGIN = window.HEADER_ORIGIN || undefined

/**
 * https://developer.tech.yandex.ru/services
 */
export const YANDEX_MAP_APIKEY = window.YANDEX_MAP_APIKEY || '1c22362d-0201-4f58-8363-60fb2f4a6957'//https://developer.tech.yandex.ru/services/3
export const YANDEX_MAP_SUGGEST_APIKEY = window.YANDEX_MAP_SUGGEST_APIKEY || 'bb93d5ae-26f3-43ba-97f2-d3bae1591846'//https://developer.tech.yandex.ru/services/53

export const VARIABLES = {
    FILES_URL,
    BASE_URL,
    YANDEX_MAP_APIKEY,
    YANDEX_MAP_SUGGEST_APIKEY,
    API_BASE_URL
}

export const PRODUCTS = {
    0: 'Другое',
    1: 'Текила',
    2: 'Ром',
    3: 'Виски',
    4: 'Jagermeister',
    5: 'Ликер',
    6: 'Настойка',
}

export const minDistance = 1800;

export const start = 43200
export const end = 127800 + minDistance

export const defaultRange = [start + (3600 * 6), start + (3600 * 19)]

export const defaultPeriodicities = {
    1: {
        range: defaultRange,
        status: 'not',
        day: 1
    },
    2: {
        range: defaultRange,
        status: 'not',
        day: 2
    },
    3: {
        range: defaultRange,
        status: 'not',
        day: 3
    },
    4: {
        range: defaultRange,
        status: 'not',
        day: 4
    },
    5: {
        range: defaultRange,
        status: 'not',
        day: 5
    },
    6: {
        range: defaultRange,
        status: 'not',
        day: 6
    },
    0: {
        range: defaultRange,
        status: 'not',
        day: 0
    },
}

export const SCHEDULE_VAR = {
    minDistance,
    start,
    end,
    defaultRange,
    defaultPeriodicities
}
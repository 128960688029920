import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const formateDateTime = (date) =>
    dayjs.utc(date).toDate().toLocaleString('ru', { timeStyle: 'short', dateStyle: 'short' })

export const formateDate = (date) =>
    dayjs.utc(date).toDate().toLocaleString('ru', { dateStyle: 'short' })

// export const formateDateToServer = (date) => date?.toDate().toLocaleString('sv-SE', { timeZone: 'UTC' })
export const formateDateToServer = (date, dataOnly = false) =>
    dataOnly ? date.format('YYYY-MM-DD') : date.utc().format('YYYY-MM-DD HH:mm:ss')

export const formateDateRU = (date) => dayjs.utc(date).toDate().toLocaleString('ru')
export const formateTime = (date) => dayjs.utc(date).local().locale('ru').format('HH:mm')
export const formateDayYear = (date) => dayjs.utc(date).local().locale('ru').format('dddd, DD MMMM')
export const formateDayMoShort = (date) => dayjs.utc(date).local().locale('ru').format('dd, DD.MM')

export const setTimeZero = (date) => date.set('hours', 0).set('minutes', 0).set('second', 0).set('millisecond', 0)
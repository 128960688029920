import { useLocalStorage } from "@uidotdev/usehooks";

const parseJwt = (token) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const useJWT = () => {
    const [authData] = useLocalStorage('authData', null)
    if(!authData) return null
    return parseJwt(authData.token)
}

export const useCurrentUser = () => {
    const [authData] = useLocalStorage('authData', null)
    // console.log('authData', authData)
    // console.log('token', parseJwt(authData.token))
    return authData?.user
}
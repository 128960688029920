import React, { } from "react";
import Grid from '@mui/material/Grid';
import { DaySelector } from "./DaySelector";
import { TimeRangeSlider } from "./TimeRangeSlider";
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TimeInformation } from "./TimeInformation";
import Typography from '@mui/material/Typography';
import { usePeriodicitieSchedule } from "./usePeriodicitieSchedule";


export const UserScheduleSchedule = ({
    setSchedule,
    schedule
}) => {
    const {
        day,
        currentRange,
        available,
        handleScheduleChecked,
        handleScheduleRange,
        ranges,
        setDay,
        statuses
    } = usePeriodicitieSchedule({
        setSchedule,
        schedule
    })

    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <Box display={'flex'} justifyContent={'center'}>
                <DaySelector
                    statuses={statuses}
                    value={day}
                    onChange={setDay}
                />
            </Box>
        </Grid>
        <Grid item xs={12}>
            <FormControlLabel control={<Switch
                checked={available}
                onChange={(event) => {
                    handleScheduleChecked(event.target.checked);
                }}
            />} label="Рабочий" />
        </Grid>
        <Grid item sx={12}>
            <Typography component="span" variant="body1" color="text.secondary">
                Допустимое время начала и завершения смены в выбраный день недели
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <TimeRangeSlider
                disabled={!available}
                day={day}
                value={currentRange}
                onChange={handleScheduleRange}
            />
        </Grid>
        <Grid item xs={12}>
            <TimeInformation
                currentDay={day}
                currentRange={currentRange}
            />
        </Grid>
    </Grid>
}
import apiClient, { queryClient } from "../http-common";
import { useQuery } from "@tanstack/react-query";
import {
    useRevalidator
} from "react-router-dom";



export const useListWorkshiftResult = (ws_id, page = 0) => {
    const { data, isFetching } = useQuery(
        {
            queryKey: ['workshift-results/index', { ws_id }],
            queryFn: async () => {
                return await apiClient.get(`workshift-results/index`, {
                    params: {
                        ws_id
                        // page: query.get("page")
                    }
                });

            },
        }
    );
    const { values = [], ...meta } = data?.data || {}
    return { values, meta, isFetching }
}
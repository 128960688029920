import apiClient, { queryClient } from "../http-common";
import { useMutation } from "@tanstack/react-query";
import {
    useRevalidator
} from "react-router-dom";

export const useSendSchedule = (user_id, { onSuccess = () => { }, onError = () => { } } = {}) => {
    // const revalidator = useRevalidator();
    return useMutation({
        mutationKey: ["users-schedule/set", { user_id }],
        mutationFn: async (data) => {
            return await apiClient.post("users-schedule/set", {
                ...data
            }, { params: { user_id } });
        },
        onSuccess: async (res) => {
            // await queryClient.invalidateQueries({ queryKey: ['workshift/active'] })
            await queryClient.invalidateQueries({ queryKey: ['users-schedule/index'] })
            // await queryClient.invalidateQueries({ queryKey: ['workshift-results/index'] })
            // await queryClient.invalidateQueries({ queryKey: [`workshift/view`] })
            // revalidator.revalidate();
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
        }
    })
}
import apiClient, { queryClient } from "../http-common";
import { useMutation } from "@tanstack/react-query";



export const useWorkshiftsCancle = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: ["workshift/cancle"],
        mutationFn: async (id) => {
            return await apiClient.put("workshift/cancle", { id });
        },
        onSuccess: (res) => {
            queryClient.invalidateQueries({ queryKey: ['workshift'] })
            queryClient.invalidateQueries({ queryKey: [`workshift/view`] })
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
        }
    })
}
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ContentLayout } from '../../Components/ContentLayout/ContentLayout'
import { Link, useParams, useSearchParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useRestaurantView } from '../../api/restaurants/useRestaurantView'
import { useCommentsList } from '../../api/rest-comments/useCommentsList'
import { useUserBanList } from '../../api/rest-user-ban/useUserBanList'
import { useMutation } from "@tanstack/react-query";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import { Comment } from '../../ui/Comment/Comment'
import Box from '@mui/material/Box';
import { YMaps, Map, Placemark, GeolocationControl, SearchControl, useYMaps } from '@pbe/react-yandex-maps';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TableLayout } from '../../Components/Table/TableLayout'
import { UserTableHead, UserTableRow } from '../../Components/Table/UserTable'
import { YandexMap } from '../../Components/YandexMap/YandexMap'
import { useSetTitle } from '../../utils/useTitle'
import { UsersAutocomplete, UsersAvailableAutocomplete } from '../../Components/Autocomplete/Autocomplete'
import { useModal } from '../../utils/useModal'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBanCreate } from '../../api/rest-user-ban/useBanCreate'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { RequestErrorMessage } from "../../ui/RequestErrorMessage/RequestErrorMessage";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useBanDelete } from "../../api/rest-user-ban/useBanDelete";
import CircularProgress from '@mui/material/CircularProgress';
import { CommentForm } from "../../Components/CommentForm/CommentForm";
import { useSendComment } from "../../api/rest-comments/useSendComment";
import { useUpdateComment } from "../../api/rest-comments/useUpdateComment";
import { useDeleteComment } from "../../api/rest-comments/useDeleteComment";
import { Preloader } from "../../ui/Preloader/Preloader";
import { VARIABLES } from '../../utils/variables';
import { useFilesByRest } from "../../api/rest-files/useFilesByRest";
import { useAddRestFile } from "../../api/rest-files/useAddRestFile";
import apiClient, { queryClient } from "../../api/http-common";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSetSpeedDial } from "../../utils/useSpeedDial";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MuiLink from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useDeleteRestFile } from "../../api/rest-files/useDeleteRestFile";
import { BannedTable } from "../../Components/BannedTable/BannedTable";
import { useWorkshiftsList } from "../../api/workshift/useWorkshiftsQuery";
import { WorkshiftTableHead, WorkshiftTableRow } from "../../Components/Table/WorkshiftTable";
import EditIcon from '@mui/icons-material/Edit';

const CommentSend = ({
    rest_id,
    onSended = () => { }
}) => {
    const { mutateAsync, isPending, error, reset } = useSendComment({
        onSuccess: onSended
    })

    return <CommentForm
        error={error}
        reset={reset}
        isPending={isPending}
        onSubmit={async (e) => {
            e.preventDefault()

            const target = e.currentTarget

            const data = new FormData(e.currentTarget);
            const v = {
                ...[...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), { rest_id }),
            }
            try {
                const r = await mutateAsync(v)
                target.reset()
            } catch (error) {
                console.warn(error)
            }
        }}
    />
}

const CommentItem = ({ value }) => {
    const [isPendingApprove, setPendingApprove] = useState(false)
    const [isPendingDelete, setPendingDelete] = useState(false)
    const { mutate: mutateApprove } = useUpdateComment({ onError: () => setPendingApprove(false) })

    const { mutate: mutateDelete } = useDeleteComment({ onError: () => setPendingDelete(false) })

    useEffect(() => {
        setPendingApprove(false)
    }, [value.approved])

    return <Comment
        value={value}
        isPendingApprove={isPendingApprove}
        isPendingDelete={isPendingDelete}
        onClickDelete={() => {
            mutateDelete(value.id)
            setPendingDelete(true)
        }}
        onClickApprove={() => {
            mutateApprove({
                approved: !value.approved,
                id: value.id
            })
            setPendingApprove(true)
        }}
    />
}

const CommentsList = ({ id }) => {
    const [page, setPage] = useState(1)
    const { values, isFetching, meta } = useCommentsList(id, page)

    const { pagination } = meta || {}

    const pageCount = Math.ceil((pagination?.totalCount || 0) / (pagination?.defaultPageSize || 1))

    return <Box sx={{ display: 'flex', flexDirection: 'column' }}>

        <Box sx={{ marginBottom: '24px', display: 'flex', flexDirection: 'column' }}>
            {!!values.length && <List sx={{ marginBottom: '12px' }}>
                {values.map(value => <CommentItem
                    key={value.id}
                    value={value}
                />)}
            </List>}

            {isFetching && <Preloader />}

            {!values.length && !isFetching && <>Комментариев нету</>}

            {pageCount > 1 && <Pagination sx={{ marginLeft: 'auto' }} page={page} count={pageCount} size="small"
                onChange={(_, page) => setPage(page)} />}
        </Box>
        <CommentSend rest_id={id} onSended={() => setPage(pageCount)} />
    </Box>
}

const CreateNewBan = ({ rest_id }) => {
    const { handleOpen } = useModal({
        Component: AddBan,
        props: {
            id: rest_id
        }
    })

    return <Button onClick={handleOpen}>Заблокировать сотрудника</Button>
}






const AddBan = ({ handleClose, id }) => {
    const [user, setUser] = useState(null)

    const { mutateAsync, isPending, error, reset } = useBanCreate()

    return <Box
        component={'form'}
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        onSubmit={async (e) => {
            e.preventDefault()
            try {
                const r = await mutateAsync({
                    rest_id: id,
                    user_id: user.id,
                    comment: 'comment'
                })
                console.log('result', r)
                handleClose()
            } catch (error) {
                console.log('result', error)
            }
        }}
    >
        <DialogTitle id="alert-dialog-title">
            Блокировка сотрудника в заведение
        </DialogTitle>
        <DialogContent>

            <Grid container spacing={3} sx={{ my: 1 }}>

                <Grid item xs={12}>
                    <UsersAvailableAutocomplete
                        onChange={(_, v) => setUser(v)}
                        selected={user}
                        rest_id={id}
                        label={'Сотрудник'}
                    />
                </Grid>

                {error && <Grid item xs={12}>
                    <RequestErrorMessage
                        error={error}
                        reset={reset}
                    />
                </Grid>}

            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Отмена</Button>
            <LoadingButton loading={isPending} disabled={!!error} variant="contained" type='submit' >
                Заблокировать
            </LoadingButton>
        </DialogActions>
    </Box>
}

const Image = styled('img')(
    ({ theme }) => `
    width: 100%;
    height: auto;
    display: block;
  `,
);


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const RulesFileUploader = ({
    rest_id
}) => {
    const { mutate, isPending: isPending2 } = useAddRestFile()
    const { mutate: upload, isPending } = useMutation({
        mutationKey: 'upload/upload',
        mutationFn: async (file) => {
            const formData = new FormData();
            formData.append("imageFile", file);
            return await apiClient.post("upload/upload", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        onSuccess: (res) => {
            const result = {
                status: res.status + "-" + res.statusText,
                headers: res.headers,
                data: res.data,
            };
            console.log('res', res)
            mutate({ rest_id, file_id: res.data.file_id, category: 'rules' })
        },
        onError: (err) => {
            console.error('err', err)
            // setGetResult(fortmatResponse(err.response?.data || err));
        }
    })
    return <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
        <LoadingButton loading={isPending || isPending2} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            Upload file
            <VisuallyHiddenInput type="file" onChange={(e) => upload(e.target.files[0])} />
        </LoadingButton>
    </Box>
}

const RulesItem = ({ id, file, name, extension, mime, rel_id }) => {
    const { isPending, mutate } = useDeleteRestFile()
    return <>
        <ListItem
            alignItems="flex-start"
            secondaryAction={
                <IconButton
                    disabled={isPending}
                    onClick={() => window.confirm('Удалить файл?') && mutate(rel_id)} edge="end" aria-label="delete">
                    {isPending ? <CircularProgress size={24} /> : <DeleteIcon />}
                </IconButton>
            }
        >
            <ListItemIcon>
                <AttachFileIcon />
            </ListItemIcon>
            <ListItemText
                primary={<MuiLink download href={file}>{name}</MuiLink>}
                secondary={<Typography
                    variant="body2"
                    fontSize={12}
                >
                    {extension}-({mime})
                </Typography>}
            />
        </ListItem>
        <Divider />
    </>
}

const RulesList = ({ rest_id }) => {
    const { isFetching, values, meta } = useFilesByRest(rest_id, 'rules')

    return <Box>
        <RulesFileUploader rest_id={rest_id} />

        {(isFetching) && <Preloader />}

        {(!values.length) && <Box>Файлов нету</Box>}

        {!!values.length && <List>
            {values.map(({ file, relatation }) => <RulesItem key={relatation.id} {...file} rel_id={relatation.id} />)}


        </List>}
    </Box>
}

const RestaurantWorkshifts = ({ restaurants_id }) => {
    const [query] = useSearchParams();
    const page = query.get("page") || 1
    const { meta, values, isFetching } = useWorkshiftsList({ tab: 'index', restaurants_id, page })

    const pageCount = Math.ceil((meta?.pagination?.totalCount || 0) / (meta?.pagination?.defaultPageSize || 1))

    return <TableLayout
        rowCount={values.length}
        colCount={6}
        isLoading={isFetching}

        pageCount={pageCount}
        currentPage={page}
        head={<WorkshiftTableHead actions />}
    >
        {values.map((value) => <WorkshiftTableRow key={value.id} {...value} actions />)}
    </TableLayout>
}

const RestaurantsViewContent = ({ value, id, ...props }) => {

    const [tab, setTab] = React.useState('map');

    const sdActions = useMemo(() => {
        return [
            { LinkComponent: Link, to: `/restaurants/update/${value.id}`, icon: <EditIcon />, name: 'r-w/edit', tooltipTitle: 'Редактировать заведение', key: 'r-w/edit' },
        ]
    }, [])


    useSetSpeedDial({ key: 'restaurants/view', values: sdActions })

    useSetTitle(value?.name || 'Заведение')

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return <ContentLayout
        title={value?.name}

    >
        <Grid container spacing={3}>

            <Grid item xs={4}>
                <Image
                    alt={value?.name}
                    src={VARIABLES.FILES_URL + `${value?.avatar?.file}`}
                />
            </Grid>

            <Grid item xs={8}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {value?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>
                            Комиссия: {value?.commission}%
                        </Typography>

                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>
                            Базовая комиссия: {value?.base_commission}
                        </Typography>

                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>
                            {value?.description}
                        </Typography>

                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>
                            {value?.address}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>
                            {value?.comment}
                        </Typography>

                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Tabs
                            variant="scrollable"
                            scrollButtons="auto"
                            value={tab} onChange={handleChange}>
                            <Tab label="Карта" value={'map'} />
                            <Tab label="Комментарии" value={'comments'} />
                            <Tab label="Блокировки" value={'banned'} />
                            <Tab label="Правила" value={'rules'} />
                            <Tab label="Смены" value={'workshifts'} />
                        </Tabs>
                    </Grid>

                    <Grid item xs={12}>
                        {tab === 'map' && <YandexMap
                            height={'40vh'}
                            key={`view_r_${value?.id}`}
                            viewOnly
                            place={value?.latitude && value?.longitude && [value.longitude, value.latitude]}
                        />}

                        {tab === 'comments' && value?.id && <CommentsList id={value?.id} />}

                        {tab === 'banned' && <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <CreateNewBan rest_id={value?.id} />
                            </Grid>
                            <Grid item xs={12}>
                                <BannedTable rest_id={value?.id} />
                            </Grid>
                        </Grid>}

                        {tab === 'rules' && value?.id && <RulesList rest_id={value?.id} />}

                        {tab === 'workshifts' && value?.id && <RestaurantWorkshifts restaurants_id={value?.id} />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </ContentLayout>
}


export const RestaurantsViewPage = ({ className, ...props }) => {
    const { id } = useParams()
    const { value, isLoading } = useRestaurantView(id)



    if (isLoading) return <ContentLayout
        title={<Preloader />}
    />
    if (!value) return <ContentLayout
        title={`404`}
    />
    return <RestaurantsViewContent id={id} value={value} />

}
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { useCitiesCreate } from '../../api/cities/useCitiesCreate'
import Typography from '@mui/material/Typography';

export const CreateCityDialog = ({ className, handleClose, ...props }) => {

    const { mutateAsync, isPending, error } = useCitiesCreate()

    return <Box
        component={'form'}
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        onSubmit={async (e) => {
            e.preventDefault()
            const data = new FormData(e.currentTarget);
            const v = {
                ...[...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {}),
            }
            console.log('data', [...data.entries()], v)
            try {
                const r = await mutateAsync(v)
                handleClose()
            } catch (error) {
                console.log('error create city', error)
            }
        }}
    >
        <DialogTitle id="alert-dialog-title">
            Добавить город
        </DialogTitle>
        <DialogContent>

            <Grid container spacing={3} sx={{ my: 1 }}>

                <Grid item xs={12}>
                    <TextField
                        label="Название"
                        name="name"
                        id="name"
                        fullWidth
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Страна"
                        name="country"
                        id="country"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Часовой пояс"
                        name="timezon"
                        id="timezon"
                        type="number"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography>Координаты из Яндекс/Google карт. Эта точка будет устанавливаться по умолчанию при выборе города.</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Долгота"
                        name="longitude"
                        id="longitude"
                        inputMode='decimal'
                        inputProps={{
                            step: 0.0000000000001,
                        }}
                        type="number"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Широта"
                        name="latitude"
                        id="latitude"
                        inputMode='decimal'
                        inputProps={{
                            step: 0.0000000000001,
                        }}
                        type="number"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Отмена</Button>
            <LoadingButton loading={isPending} variant="contained" type='submit' >
                Добавить
            </LoadingButton>
        </DialogActions>
    </Box>
}
import {
    Link
} from "react-router-dom";
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import TapasIcon from '@mui/icons-material/Tapas';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import { CreateCityDialog } from "../CreateCityDialog/CreateCityDialog";
import { useModal } from "../../utils/useModal";
import { useJWT } from "../../utils/useCurrentUser";
import { BaseMenuListItem } from "../../ui/BaseMenuListItem/BaseMenuListItem";


export const mainListItems = (
    <React.Fragment>

        <BaseMenuListItem
            icon={<DashboardIcon />}
            listItemButtonProps={{ LinkComponent: Link, to: '/' }}
            listItemTextProps={{ primary: "Главная" }}
        />

        <BaseMenuListItem
            icon={<PeopleIcon />}
            listItemButtonProps={{ LinkComponent: Link, to: '/users' }}
            listItemTextProps={{ primary: "Сотрудники" }}
        />

        <BaseMenuListItem
            icon={<TapasIcon />}
            listItemButtonProps={{ LinkComponent: Link, to: '/restaurants' }}
            listItemTextProps={{ primary: "Заведения" }}
        />

        <BaseMenuListItem
            icon={<WorkHistoryIcon />}
            listItemButtonProps={{ LinkComponent: Link, to: '/workshifts' }}
            listItemTextProps={{ primary: "Смены" }}
        />

    </React.Fragment>
);

const AddCityButton = () => {
    const { handleOpen } = useModal({
        Component: CreateCityDialog,
    })
    return <BaseMenuListItem
        icon={<LocationCityIcon />}
        listItemButtonProps={{ onClick: handleOpen }}
        listItemTextProps={{ primary: "Доавить город" }}
    />
}

export const secondaryListItems = (
    <React.Fragment>
        {/* <ListSubheader component="div" inset>
            Saved reports
        </ListSubheader> */}
        <AddCityButton />
        {/* <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Last quarter" />
        </ListItemButton>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Year-end sale" />
        </ListItemButton> */}
    </React.Fragment>
);

export const drawerWidth = 240;
// 
export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(0),//theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);




export const Menu = ({ toggleDrawer, open }) => {

    return <Drawer variant="permanent" open={open}>
        <Toolbar
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
            }}
        >
            <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
            </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
        </List>
    </Drawer>
}
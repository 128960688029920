import React, { useEffect, useRef, useState } from "react";
import { ContentLayout } from '../../Components/ContentLayout/ContentLayout'
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ImageUploader } from '../../Components/ImageUploader/ImageUploader'
import LoadingButton from '@mui/lab/LoadingButton';
import { useRestaurantsCreate } from '../../api/restaurants/useRestaurantsCreate'
import { CitySelect } from "../../Components/CitySelect/CitySelect";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { YandexMap } from "../../Components/YandexMap/YandexMap";
import { RequestErrorMessage } from "../../ui/RequestErrorMessage/RequestErrorMessage";
import { VARIABLES } from '../../utils/variables';

export const RestaurantsCreatePage = ({ className, ...props }) => {
    const [fileResult, setFileResult] = useState(null)

    const [place, setPlace] = useState([-16.460174, -133.395840])
    const ymap = useRef(null)

    const navigate = useNavigate();
    const { mutate, error, reset, isPending } = useRestaurantsCreate({
        onSuccess: ({ data }) => {
            navigate(`/restaurants/view/${data.id}`);
        }
    })

    return <ContentLayout>

        <Box component={'form'} noValidate onSubmit={(e) => {
            e.preventDefault()
            const data = new FormData(e.currentTarget);
            const v = [...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {
                avatar: fileResult?.file_id
            })
            console.log('data', [...data.entries()], v)
            mutate(v)

        }}>
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12}>
                    <ImageUploader
                        src={VARIABLES.FILES_URL + `${fileResult?.url}`}
                        onUpload={(data) => {
                            setFileResult(data)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Название"
                        name="name"
                        id="name"
                        fullWidth
                    // defaultValue={value?.name}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Комиссия"
                        name="commission"
                        id="commission"
                        fullWidth
                        // defaultValue={value?.commission}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Базовая комиссия"
                        name="base_commission"
                        id="base_commission"
                        fullWidth
                        // defaultValue={value?.base_commission}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Описание"
                        name="description"
                        id="description"
                        multiline
                        fullWidth
                        rows={4}
                    // defaultValue={value?.description}
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <TextField
                        label="Адрес"
                        name="address"
                        id="address"
                        multiline
                        fullWidth
                        rows={4}
                    // defaultValue={value?.address}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Комментарий"
                        name="comment"
                        id="comment"
                        multiline
                        fullWidth
                        rows={4}
                    // defaultValue={value?.comment}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="restSelectCity-select-small-label">Город</InputLabel>
                        <CitySelect
                            // anyVariant
                            label="Город"
                            name="city_id"
                            labelId="restSelectCity-select-small-label"
                            id="restSelectCity-select-small"
                            onChange={(v) => {
                                const pos = [v?.longitude, v?.latitude]
                                ymap.current?.setCenter(pos)
                                setPlace(pos)
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <YandexMap
                        height={'40vh'}
                        latitudeName={'latitude'}
                        longitudeName={'longitude'}
                        key={`create_r`}
                        onChange={setPlace}
                        onLoad={(m) => ymap.current = m}
                        place={place}
                    />
                </Grid>
                {error && <Grid item xs={12}>
                    <RequestErrorMessage
                        error={error}
                        reset={reset}
                    />
                </Grid>}
                <Grid item xs={10}></Grid>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" loading={isPending} variant="contained" >Создать</LoadingButton>
                </Grid>
            </Grid>
        </Box>
    </ContentLayout>
}
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContentLayout } from '../../Components/ContentLayout/ContentLayout'
import { useRestaurantsQuery } from '../../api/restaurants/useRestaurantsQuery'
import { useSetTitle } from '../../utils/useTitle'
import { TableLayout } from '../../Components/Table/TableLayout'
import { RestaurantTableHead, RestaurantTableRow } from "../../Components/Table/RestaurantTable";
import { useSetSpeedDial } from "../../utils/useSpeedDial";
import AddIcon from '@mui/icons-material/Add';

const sdActions = {
    key: 'restaurants/index', values: [
        { LinkComponent: Link, to: '/restaurants/create', icon: <AddIcon />, name: 'r-i/create', tooltipTitle: 'Добавить заведение', key: '-i/create' },
    ]
}


export const RestaurantsPage = ({ className, ...props }) => {
    useSetTitle('Заведения')
    useSetSpeedDial(sdActions)
    const { values = [], meta, currentPage, isLoading } = useRestaurantsQuery()

    const pageCount = Math.ceil((meta?.pagination?.totalCount || 0) / (meta?.pagination?.defaultPageSize || 1))

    return <ContentLayout
        title={'Заведения'}
    >
        <TableLayout
            rowCount={values.length}
            colCount={4}
            isLoading={isLoading}

            pageCount={pageCount}
            currentPage={currentPage}
            head={<RestaurantTableHead actions />}
        >
            {values.map((value) => <RestaurantTableRow key={value.id} {...value} />)}
        </TableLayout>
    </ContentLayout>
}
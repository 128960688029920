import apiClient, { queryClient } from "../http-common";
import { useMutation } from "@tanstack/react-query";

export const useCreateUser = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: 'user/create',
        mutationFn: async (value) => {
            return await apiClient.post("user/create", {
                ...value
            });
        },
        onSuccess: (res) => {
            if (res.data.error) {
                return onSuccess(res)
            }
            queryClient.invalidateQueries({ queryKey: ['user'] })
            onSuccess(res)
        },
        onError,
    })
}
import apiClient, { queryClient } from "../http-common";
import { useMutation } from "@tanstack/react-query";


export const useRestaurantsUpdate = (id, { onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: ["restaurants/update"],
        mutationFn: async (value) => {
            return await apiClient.put("restaurants/update", value, { params: { id } });
        },
        onSuccess: (res) => {
            queryClient.invalidateQueries({ queryKey: ['restaurants'] })
            queryClient.invalidateQueries({ queryKey: [`/restaurants/view`, { id }] })
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
        }
    })
}
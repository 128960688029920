import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { FileItem } from "../../../ui/FileItem/FileItem";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteUserPersonalFile } from "../../../api/users-personal-files/useDeleteUserPersonalFile";

export const UserFileItem = ({
    file,
    relatation,
}) => {
    const [isPending, setPending] = useState(false)
    const { mutate, reset, error } = useDeleteUserPersonalFile()
    return <FileItem
        {...file}
        secondaryAction={
            <Tooltip disableFocusListener placement='top' title={'Удалить файл'}>
                <IconButton
                    disabled={isPending}
                    onClick={() => {
                        if (window.confirm('Удалить файл?')) {
                            setPending(true)
                            mutate(relatation.id)
                        }
                    }}
                    edge="end" aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        }
    />
}
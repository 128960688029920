import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
// import { errorReports } from "../../api/errorReports";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import apiClient, { queryClient } from "../../api/http-common";
import { useMutation } from "@tanstack/react-query";
import Typography from '@mui/material/Typography';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const FileUploader = ({
    onSuccess = () => { },
    error,
    defaultFile,
    text = 'Выбрать файл',
    helpText = '',
    fullWidth = true,
    privateFile = false
}) => {
    const [result, setResult] = useState(defaultFile)
    const { mutate: upload, isPending } = useMutation({
        mutationKey: privateFile ? 'upload/upload-private' : 'upload/upload',
        mutationFn: async (file) => {
            const formData = new FormData();
            formData.append("imageFile", file);
            return await apiClient.post(privateFile ? 'upload/upload-private' : "upload/upload", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        onSuccess: (res) => {
            console.log('res', res)
            setResult(res.data)
            onSuccess(res)
        },
        onError: (err) => {
            console.error('err', err)
            // errorReports(err)
        }
    })
    return <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
        <VisuallyHiddenInput name="file_id" value={result?.file?.id} onChange={() => { }} />
        <Box>
            <Typography color={error ? 'error' : 'info'}>{result?.file?.name || helpText}</Typography>
        </Box>
        <LoadingButton color={error ? 'error' : 'primary'} fullWidth={fullWidth} loading={isPending} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            {text}
            <VisuallyHiddenInput type="file" onChange={(e) => upload(e.target.files[0])} />
        </LoadingButton>
    </Box>
}

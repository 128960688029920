import { createContext, useContext, useState, useMemo, useEffect } from 'react';

const defaultContext = {
    setEnabled: (enabled) => { },
    enabled: false
}

const ProgressBarContext = createContext(defaultContext);

export const ProgressBarProvider = (props) => {
    const [enabled, setEnabled] = useState(false)
    const context = useMemo(() => ({
        enabled,
        setEnabled
    }), [enabled, setEnabled])
    return <ProgressBarContext.Provider {...props} value={context} />
}

export const useSetProgressBar = (enabled) => {
    const { setEnabled } = useContext(ProgressBarContext);
    useEffect(() => {
        setEnabled(enabled)
        return () => {
            setEnabled(false)
        }
    }, [enabled])
}
export const useGetProgressBar = () => {
    const { enabled } = useContext(ProgressBarContext);
    return enabled
}
import apiClient from "../http-common";
import { useQuery } from "@tanstack/react-query";
import {
    useLocation,
    useParams,
    useSearchParams
} from "react-router-dom";
import { useSetProgressBar } from '../../utils/useProgressBar'

export const useWorkshiftsQuery = ({sort, user_id}) => {
    const { tab = 'active' } = useParams()
    const [query] = useSearchParams();
    const page = query.get("page") || 1

    const { data, isFetching } = useQuery({
        queryKey: ["workshift", { tab, page, sort }],
        queryFn: async () => {
            return await apiClient.get(`workshift/${tab}`, {
                params: {
                    page: query.get("page"),
                    sort: !!sort ? sort : undefined,
                    user_id
                }
            });
        },
        staleTime: 100000
    });

    useSetProgressBar(isFetching)
    const { values = [], ...meta } = data?.data || {}
    return { values, meta, currentPage: page, isFetching, tab }

}

export const useWorkshiftsList = (p = { tab: 'active', page: 1 }) => {
    const { tab, page, ...params } = p
    const { data, isFetching } = useQuery({
        queryKey: ["workshift", { tab, page, ...params }],
        queryFn: async () => {
            return await apiClient.get(`workshift/${tab}`, {
                params: {
                    page,
                    ...params
                }
            });
        },
        staleTime: 0
    });

    const { values = [], ...meta } = data?.data || {}
    return { values, meta, isFetching }

}
import { useMutation } from "@tanstack/react-query";
import apiClient, { queryClient } from "../http-common";
import React, { useState } from 'react';

export const useChangeResultStatus = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: "workshift-results/change-status",
        mutationFn: async ({
            status,
            reject_reason,
            id
        }) => {
            return await apiClient.put("workshift-results/change-status", {
                status,
                reject_reason
            }, { params: { id } });
        },
        onSuccess: async (res) => {
            await queryClient.invalidateQueries({ queryKey: ['workshift-results/index'] })
            await queryClient.invalidateQueries({ queryKey: ['workshift/view'] })
            await queryClient.invalidateQueries({ queryKey: ['workshift/index'] })
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
            console.log('rest-comments/create err', err)
        },
    })
}
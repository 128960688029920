import React, { useState, memo } from 'react'
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { isAvailable } from './utils';



export const CalendarDay = memo(({ workSchedule, workDates, day, outsideCurrentMonth, ...props }) => {
    const [open, setOpen] = useState(false);
    const isWork = isAvailable(workDates, day, workSchedule)

    let badgeContent = isWork ? <CheckIcon fontSize="small" color={'success'} /> : <CloseIcon fontSize="small" color={'error'} />
    badgeContent = !outsideCurrentMonth ? badgeContent : undefined
    badgeContent = props.disabled ? undefined : badgeContent

    const title = isWork ? 'Доступен для смены' : 'Не рабочий'

    const ttOpen = !!badgeContent ? open : false
    return (
        <Tooltip disableFocusListener open={ttOpen} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} placement='top' title={title}>
            <Badge
                key={day.toString()}
                overlap="circular"
                badgeContent={badgeContent}
            >
                <PickersDay {...props} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        </Tooltip>
    )
})
import React, { useMemo, memo } from 'react'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { CalendarDay } from './CalendarDay';

const CURRENT_DATE = dayjs()

export const DateSelector = memo(({
    onChange,
    value,
    workSchedule,
    workDates
}) => {
    const maxDate = useMemo(() => {
        let maxDate = dayjs().month(dayjs().month() + 2)
        maxDate = dayjs(maxDate).date(dayjs().daysInMonth())
        return maxDate
    }, [])

    const { slotProps, slots } = useMemo(() => ({
        slots: {
            day: CalendarDay,
        },
        slotProps: {
            day: {
                workSchedule,
                workDates
            },
        }
    }))
    return <DateCalendar
        minDate={CURRENT_DATE}
        maxDate={maxDate}
        value={value}
        onChange={onChange}
        slots={slots}
        slotProps={slotProps}
    />
})
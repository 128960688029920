import apiClient from "../http-common";
import { useQuery } from "@tanstack/react-query";
import {
    useLocation,
    useParams,
    useSearchParams
} from "react-router-dom";
import { useSetProgressBar } from '../../utils/useProgressBar'

export const useUserBanList = ({ rest_id, user_id, page = 0 }) => {
    const { data, isFetching } = useQuery(
        {
            queryKey: ['rest-user-ban', { rest_id, user_id, page }],
            queryFn: async () => {
                return await apiClient.get(`rest-user-ban/index`, {
                    params: {
                        rest_id,
                        user_id,
                        page,
                        sort: '-create_at'
                        // page: query.get("page")
                    }
                });

            },
            staleTime: 3000000
        }
    );
    const { values = [], ...meta } = data?.data || {}
    return { values, meta, isFetching }
}
import apiClient from "../http-common";
import { useQuery } from "@tanstack/react-query";

export const useWorkshiftsView = (id) => {
    return useQuery({
        queryKey: ["workshift/view", { id }],
        queryFn: async () => {
            return await apiClient.get("workshift/view", { params: { id } });
        },
    });
}

import { remove } from 'lodash';
import { createContext, useContext, useState, useMemo, useEffect, useRef } from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';

const defaultContext = {
    addActions: (key, actions) => { },
    setActionsByKey: (key, actions) => { },
    getActions: (key) => [],
    removeActions: (key) => { },
    hidden: false,
    actions: {
        // base: [
        //     { icon: <FileCopyIcon />, name: 'Copy', key: 'Copy' },
        //     { icon: <SaveIcon />, name: 'Save', key: 'Save' },
        //     { icon: <PrintIcon />, name: 'Print', key: 'Print' },
        //     { icon: <ShareIcon />, name: 'Share', key: 'Share' },
        // ]
    }
}

const SpeedDialContext = createContext(defaultContext);

export const SpeedDialProvider = (props) => {
    const [actions, setActions] = useState(defaultContext.actions)
    const actionsRef = useRef(actions)
    actionsRef.current = actions
    const context = useMemo(() => ({
        addActions: (key, values = []) => {
            setActions((state) => ({ ...state, [key]: [...(state[key] || []), ...values] }))
        },
        setActionsByKey: (key, values = []) => {
            setActions((state) => ({ ...state, [key]: values }))
        },
        getActions: (key) => actionsRef.current[key] || [],
        removeActions: (key, values) => {
            if (actionsRef.current[key]) {
                let a = []
                if (values) a = actionsRef.current[key].filter(v => !values.find(x => x.key === v.key))
                setActions((state) => ({ ...actionsRef.current, [key]: a }))
            }
        },
        actions: actions
    }), [actions])
    return <SpeedDialContext.Provider {...props} value={context} />
}

export const useSetSpeedDial = ({ key, values } = {}) => {
    const { actions, addActions, getActions, removeActions, setActionsByKey } = useContext(SpeedDialContext);
    useEffect(() => {
        if (!!key && !!values) {
            setActionsByKey(key, values)
        }
    }, [key, values])
    useEffect(() => {
        return () => {
            setActionsByKey(key)
        }
    }, [])
    return { actions, addActions, getActions, removeActions, setActionsByKey }
}
export const useGetSpeedDialActions = () => {
    const { actions } = useContext(SpeedDialContext);

    return Object.values(actions).reduce((a, c) => ([...a, ...c]), [])
}
import React, { memo } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formateSecondToHour, formateTimeOfDay } from './utils';


export const TimeInformation = memo(({
    boxProps,
    currentDay,
    currentRange
}) => {
    return <Box {...boxProps}>
        <Box>
            <Typography component="span" variant="body1" color="text.secondary">
                Начало:&nbsp;
            </Typography>
            <Typography component="span" variant="body1" color="text.primary">
                {
                    formateTimeOfDay(currentDay, currentRange[0])
                }
            </Typography>
        </Box>

        <Box>
            <Typography component="span" variant="body1" color="text.secondary">
                Завершение:&nbsp;
            </Typography>
            <Typography component="span" variant="body1" color="text.primary">
                {
                    formateTimeOfDay(currentDay, currentRange[1])
                }
            </Typography>
        </Box>
        <Box>
            <Typography component="span" variant="body1" color="text.secondary">
                Продолжительность:&nbsp;
            </Typography>
            <Typography component="span" variant="body1" color="text.primary">
                {
                    formateSecondToHour(Math.round(currentRange[1] - currentRange[0]))
                }
            </Typography>
        </Box>
    </Box>
})
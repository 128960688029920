import React, { } from "react";
import { FileUploader } from "../../../Components/FileUploader/FileUploader";
import { useAddInformationFile } from "../../../api/information-files/useAddInformationFile";


export const UsersFilesUploader = ({ }) => {
    const { mutateAsync } = useAddInformationFile({
        onSuccess: () => {

        }
    })
    return <FileUploader
        text={"Загрузить"}
        fullWidth={false}
        privateFile
        onSuccess={(result) => {
            mutateAsync({
                file_id: result.data.file.id,
                type: 'info'
            })
        }}
    />
}
import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useBlocker } from 'react-router-dom';


const DefaultComponent = ({
    handleClose
}) => {
    return <>
        <DialogTitle id="alert-dialog-title">
            {"This is default dialog body"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Set component
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleClose} autoFocus>
                Agree
            </Button>
        </DialogActions>
    </>
}
const defaultContext = {
    handleOpen: (props = {}, Component) => { },
    handleClose: () => { },
    open: false,
    render: {
        Component: DefaultComponent,
        props: {},
        DialogProps: {
            fullWidth: true,
            scroll: 'body',
            maxWidth: 'sm',
        }
    },
    setRender: (render) => { }
}

const ModalContext = createContext(defaultContext);

export const useModal = ({
    Component = DefaultComponent,
    props = {},
    DialogProps = {}
} = {}, deps = []) => {
    const context = useContext(ModalContext);
    const [current, setCurrent] = useState({
        Component,
        props,
        DialogProps: {
            ...context.render.DialogProps,
            ...DialogProps
        }
    })

    useEffect(() => {
        setCurrent({
            Component,
            props,
            DialogProps: {
                ...context.render.DialogProps,
                ...DialogProps
            }
        })
    }, [...deps])

    // useEffect(() => {
    //     context.setRender({
    //         Component,
    //         props,
    //         DialogProps: {
    //             ...context.render.DialogProps,
    //             ...DialogProps
    //         }
    //     })
    // }, [...deps])

    return {
        ...context,
        handleOpen: (p = {}, Comp) => {
            context.handleOpen({
                ...current,
                Component: Comp || Component,
                props: {
                    ...props,
                    ...p
                }
            })
        }
    }
}

export const ModalProvider = ({ children, ...props }) => {
    const [open, setOpen] = useState(false);
    const [render, setRender] = useState(defaultContext.render);

    const context = useMemo(() => ({
        ...defaultContext,
        open,
        handleOpen: (current) => {
            setRender(current)
            setOpen(true)
        },
        handleClose: () => setOpen(false),
        render: {
            ...render,
            props: {
                ...render.props,
                handleClose: () => setOpen(false)
            }
        },
        setRender: (render) => {
            setOpen(false)
            setRender(render)
        }
    }), [open, setOpen, render])

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            open &&
            (currentLocation.search !== nextLocation.search || currentLocation.pathname !== nextLocation.pathname)
    );

    useEffect(() => {
        if (blocker.state === "blocked" && open) {
            setOpen(false)
            blocker.reset()
        }
    }, [blocker.state, open])

    const { Component, props: renderProps, DialogProps } = render
    return <ModalContext.Provider {...props} value={context} >
        {children}

        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            {...DialogProps}
        >
            <Component
                {...{ ...renderProps, handleClose: () => setOpen(false) }}
            />
        </Dialog>
    </ModalContext.Provider>
}



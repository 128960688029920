import React, { useEffect, useState } from "react";
import { ContentLayout } from '../../Components/ContentLayout/ContentLayout'
import { Link } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSetTitle } from '../../utils/useTitle'
import { useWorkshiftsQuery } from '../../api/workshift/useWorkshiftsQuery'
import { TableLayout } from "../../Components/Table/TableLayout";
import { ActionCancelWorkshift, WorkshiftTableHead, WorkshiftTableRow, CollapseWorkshiftTableRow } from "../../Components/Table/WorkshiftTable";
import { useWorkshiftsUpdate } from "../../api/workshift/useWorkshiftsUpdate";
import { useWorkshiftsCancle } from "../../api/workshift/useWorkshiftsCancle";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useSetSpeedDial } from "../../utils/useSpeedDial";
import AddIcon from '@mui/icons-material/Add';
import { ResultsTableLoader } from "../WorkshiftsView/ResultsTable";

export const ActionCancel = ({ id }) => {
    const { mutate, isPending } = useWorkshiftsCancle()
    return <ActionCancelWorkshift disabled={isPending} onClick={() => window.confirm('Отменить смену?') && mutate(id)} />
}

const createSortQueryParams = (orderMap) => {
    return Object.entries(orderMap).reduce((acc, [key, value]) => {
        return [...acc, {
            asc: key,
            desc: `-${key}`
        }[value]]
    }, []).filter(Boolean)
}

const sdActions = {
    key: 'workshifts/index', values: [
        { LinkComponent: Link, to: '/workshifts/create', icon: <AddIcon />, name: 'w-i/create', tooltipTitle: 'Создать смену', key: 'w-i/create' },
    ]
}





export const WorkshiftsPage = ({ className, ...props }) => {
    useSetTitle('Смены')
    useSetSpeedDial(sdActions)

    const [orderMap, setOrderMap] = useLocalStorage(`workshifts_page_sort`, {})

    const { meta, currentPage, values, tab, isFetching } = useWorkshiftsQuery({ sort: createSortQueryParams(orderMap).join(',') })


    const pageCount = Math.ceil((meta?.pagination?.totalCount || 0) / (meta?.pagination?.defaultPageSize || 1))
    return <ContentLayout
        title={<Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tab}
        >
            <Tab LinkComponent={Link} label="Активные" value={'active'} to={'/workshifts/active'} />
            <Tab LinkComponent={Link} label="Ожидают проверки" value={'wait'} to={'/workshifts/wait'} />
            <Tab LinkComponent={Link} label="Ожидают результата" value={'wait-result'} to={'/workshifts/wait-result'} />
            <Tab LinkComponent={Link} label="Законченные" value={'finished'} to={'/workshifts/finished'} />
            <Tab LinkComponent={Link} label="Все" value={'all'} to={'/workshifts/all'} />
        </Tabs>}
    >
        <TableLayout
            pageCount={pageCount}
            currentPage={currentPage}
            rowCount={values.length}
            colCount={6}
            isLoading={isFetching}
            head={<WorkshiftTableHead actions
                order={orderMap}
                // orderBy={orderBy}
                onChangeOrder={({ order, orderBy }) => {
                    setOrderMap({
                        // ...orderMap,
                        [orderBy]: order
                    })
                }} />}
        >
            {values.map((value) => tab === 'wait' ?
                <CollapseWorkshiftTableRow key={value.id} {...value} actions={
                    <ActionCancel id={value.id} />
                }  >
                    <ResultsTableLoader ws_id={value.id} commission={value.commission} base_commission={value.base_commission} />
                </CollapseWorkshiftTableRow> :
                <WorkshiftTableRow key={value.id} {...value} actions={
                    <ActionCancel id={value.id} />
                } />
            )}
        </TableLayout>
    </ContentLayout>
}
import apiClient, { queryClient } from "../http-common";
import { useMutation } from "@tanstack/react-query";

export const useBanCreate = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: ['rest-user-ban/create'],
        mutationFn: async (value) => {
            return await apiClient.post("rest-user-ban/create", {
                ...value,

            });
        },
        onSuccess: (res) => {
            if (res.data.error) {
                return onSuccess(res)
            }
            queryClient.invalidateQueries({ queryKey: ['rest-user-ban'] })
            queryClient.invalidateQueries({ queryKey: ['restaurants/available'] })
            queryClient.invalidateQueries({ queryKey: ['user/available'] })
            onSuccess(res)
        },
        onError,
    })
}
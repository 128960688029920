import apiClient, { queryClient } from "../http-common";
import { useMutation } from "@tanstack/react-query";

export const useRestaurantsCreate = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: ['restaurants/create'],
        mutationFn: async (value) => {
            return await apiClient.post("restaurants/create", {
                ...value,
                // "longitude": 55.7640666,
                // "latitude": 37.591602
            });
        },
        onSuccess: (res) => {
            if (res.data.error) {
                return onSuccess(res)
            }
            queryClient.invalidateQueries({ queryKey: ['restaurants'] })
            onSuccess(res)
        },
        onError,
    })
}
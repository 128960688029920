import apiClient from "../http-common";
import { useQuery } from "@tanstack/react-query";

export const useScheduleByUser = (user_id) => {
    return useQuery(
        {
            queryKey: ['users-schedule/index', { user_id }],
            queryFn: async () => {
                return await apiClient.get(`users-schedule/index`, {
                    params: {
                        user_id
                        // page: query.get("page")
                    }
                });

            },
            staleTime: 3000000
        }
    );
}
import React, { memo, useCallback } from 'react'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DayToggleButton } from './DayToggleButton';


export const DaySelector = memo(({
    value = 1,
    statuses = {},
    onChange = (day) => { }
}) => {
    const handleChange = useCallback((_, newDay) => onChange(newDay === null ? value : newDay), [])
    return <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        aria-label="Выбор дня недели"
    >
        <DayToggleButton
            value={1}
            status={statuses[1]}
        >
            пн
        </DayToggleButton>
        <DayToggleButton
            value={2}
            status={statuses[2]}
        >
            вт
        </DayToggleButton>
        <DayToggleButton
            value={3}
            status={statuses[3]}
        >
            ср
        </DayToggleButton>
        <DayToggleButton
            value={4}
            status={statuses[4]}
        >
            чт
        </DayToggleButton>
        <DayToggleButton
            value={5}
            status={statuses[5]}
        >
            пт
        </DayToggleButton>
        <DayToggleButton
            value={6}
            status={statuses[6]}
        >
            сб
        </DayToggleButton>
        <DayToggleButton
            value={0}
            status={statuses[0]}
        >
            вс
        </DayToggleButton>
    </ToggleButtonGroup>
})
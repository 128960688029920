import React, { useEffect, useState } from "react";
import { ContentLayout } from '../../Components/ContentLayout/ContentLayout'
import {
    useNavigate
} from "react-router-dom";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ImageUploader } from '../../Components/ImageUploader/ImageUploader'
import LoadingButton from '@mui/lab/LoadingButton';
import { useCreateUser } from '../../api/user/useCreateUser'
import { CitySelect } from "../../Components/CitySelect/CitySelect";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { RequestErrorMessage } from "../../ui/RequestErrorMessage/RequestErrorMessage";
import { YandexMap } from "../../Components/YandexMap/YandexMap";
import { VARIABLES } from '../../utils/variables';
import { useRef } from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formateDateToServer } from "../../utils/utils";
import dayjs from "dayjs";

export const UsersCreatePage = ({ className, ...props }) => {
    const navigate = useNavigate();

    const [place, setPlace] = useState([-16.460174, -133.395840])
    const ymap = useRef(null)

    const [fileResult, setFileResult] = useState(null)
    const [birthdate, setBirthdate] = useState()

    const { mutate, isPending, error, reset } = useCreateUser({
        onSuccess: (res) => {
            if (res.data.error) {
                return;
            }
            navigate(`/users/view/${res.data.id}`);
        }
    })


    return <ContentLayout>
        <Box component="form" onSubmit={(e) => {
            e.preventDefault()
            const data = new FormData(e.currentTarget);
            const v = [...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {
                avatar: fileResult?.file_id,
                birth_date: formateDateToServer(birthdate)
            })
            console.log('data', [...data.entries()], v)
            mutate(v)
        }} noValidate sx={{ mt: 1 }}>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <ImageUploader
                        variant={'rounded'}
                        imgSx={{ width: 250, height: 250 }}
                        src={VARIABLES.FILES_URL + `${fileResult?.url}`}
                        onUpload={(data) => {
                            setFileResult(data)
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Email"
                        name="email"
                        id="email"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Телефон"
                        name="phone"
                        id="phone"
                        fullWidth
                    />
                </Grid>


                <Grid item xs={12}>
                    <TextField
                        label="Фамилия"
                        name="last_name"
                        id="last_name"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Имя"
                        name="first_name"
                        id="first_name"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Отчество"
                        name="middle_name"
                        id="middle_name"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <DatePicker
                            label="Дата рождения"
                            value={birthdate}
                            onChange={(newValue) => setBirthdate(newValue)}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="username"
                        name="username"
                        id="username"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="restSelectCity-select-small-label">Город</InputLabel>
                        <CitySelect
                            // anyVariant
                            label="Город"
                            name="city_id"
                            labelId="restSelectCity-select-small-label"
                            id="restSelectCity-select-small"
                            onChange={(v) => {
                                const pos = [v?.longitude, v?.latitude]
                                ymap.current?.setCenter(pos)
                                setPlace(pos)
                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <YandexMap
                        height={'40vh'}
                        latitudeName={'latitude'}
                        longitudeName={'longitude'}
                        key={`create_r`}
                        onChange={setPlace}
                        onLoad={(m) => ymap.current = m}
                        place={place}
                    />
                </Grid>


                {error && <Grid item xs={12}>
                    <RequestErrorMessage
                        error={error}
                        reset={reset}
                    />
                </Grid>}

                <Grid item xs={10}></Grid>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton loading={isPending} type="submit" variant="contained" disabled={false} >Создать</LoadingButton>
                </Grid>
            </Grid>

        </Box>

    </ContentLayout>
}
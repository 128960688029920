import React, { } from "react";
import { useSearchParams } from "react-router-dom";
import { TableLayout } from "../../../Components/Table/TableLayout";
import { WorkshiftTableHead, WorkshiftTableRow } from "../../../Components/Table/WorkshiftTable";
import { useWorkshiftsList } from "../../../api/workshift/useWorkshiftsQuery";


export const UserWorkshifts = ({ user_id }) => {
    const [query] = useSearchParams();
    const page = query.get("page") || 1
    const { meta, values, isFetching } = useWorkshiftsList({ tab: 'index', user_id, page })

    const pageCount = Math.ceil((meta?.pagination?.totalCount || 0) / (meta?.pagination?.defaultPageSize || 1))

    return <TableLayout
        rowCount={values.length}
        colCount={6}
        isLoading={isFetching}

        pageCount={pageCount}
        currentPage={page}
        head={<WorkshiftTableHead actions />}
    >
        {values.map((intern) => <WorkshiftTableRow key={intern.id} {...intern} actions />)}
    </TableLayout>
}